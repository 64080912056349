import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { EfilingService } from './core/services/efiling.service';
import { WebApiService } from './core/services/web-api.service';
import { NavService } from './core/services/nav.service';
import { TokenInterceptor } from './core/intercepters/token.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppGatewayComponent } from './core/appgateway.component';
import { CentralAPIService } from './core/services/central-api.service';
import { IdentityInterceptor } from './core/interceptors/identity.service';
import { CacheControlInterceptor } from './core/interceptors/cache-control.service';
import { GlobalServiceDTO } from 'app/modules/filing-shared/global-shareval'

@NgModule({
    declarations: [
        AppComponent,
        AppGatewayComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
          delay             : 0,
          passThruUnknownUrl: true
      }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        FlexLayoutModule,

        // App modules
        LayoutModule,
        SampleModule,

        SharedModule,
        AppRoutingModule,
    ],
    providers: [EfilingService, WebApiService, NavService, CentralAPIService,GlobalServiceDTO,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: IdentityInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheControlInterceptor,
        multi: true
      }
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
