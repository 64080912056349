import { Injectable } from '@angular/core';
import { WebApiService } from './web-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { GenericActionResult } from '../models';
import { uscfLoginDTO } from '../models/uscfLoginDTO.model';
import { UserDTO } from '@app/modules/user-management/user-management.component';
import { AuthenticationService } from './authentication.service';
import { Md5 } from 'ts-md5';
import { EfilingService } from './efiling.service';
import { MatSnackBar } from '@angular/material';
import { tap } from 'rxjs/operators';

export interface Role {
  roleid: number;
  applicationId: number;
  rolename: string;
  rolecode: string;
}

export interface Firm {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(
    private api: WebApiService,
    private authentication: AuthenticationService,
    private efiling: EfilingService,
    private snack: MatSnackBar) { }

  roles = new BehaviorSubject<Role[]>(null);
  firm = new BehaviorSubject<Firm>(null);
  companyUsers = new BehaviorSubject<UserDTO[]>(null);
  currentUser = new BehaviorSubject<UserDTO>(null);

  allRoles = new BehaviorSubject<Role[]>(null);

  appCode = new BehaviorSubject<string>('EFILING');

  getUserRoles(): Observable<GenericActionResult> {
    return this.api.post('/identity/getUserRoles', {})
      .pipe(tap(response => this.roles.next(response.data)));
  }

  getFirm(): Observable<GenericActionResult> {
    return this.api.post('/identity/getFirm')
      .pipe(tap(response => this.firm.next(response.data)));
  }

  getAllCompanyUsers(): Observable<GenericActionResult> {
    return this.api.get('/identity/getAllCompanyUsers')
      .pipe(tap(response => this.companyUsers.next(response.data)));
  }

  putSingleUser(user: UserDTO): Observable<GenericActionResult> {
    this.companyUsers.next(null);
    return this.api.post('/identity/editSingleUser', user);
  }

  mapToLogin(user: UserDTO): uscfLoginDTO {
    const login = Object.assign({}, user);
    return login as unknown as uscfLoginDTO;
  }

  mapToUser(user: uscfLoginDTO): UserDTO {
    const target = Object.assign({}, user);
    return target as unknown as UserDTO;
  }

  addFirmUser(user: uscfLoginDTO): Observable<GenericActionResult> {
    this.companyUsers.next(null);
    if (!user.password) {
      user.password = Math.random().toString(36).slice(-8);
    }
    return this.api.post('/identity/addFirmUser', user);
  }

  getApplicationRoles(): Observable<GenericActionResult> {
    return this.api.post('/identity/ApplicationRoles')
      .pipe(tap(response => this.allRoles.next(response.data)));
  }

  startUserResetPassword(email: string, silent: boolean) {
    const token = Md5.hashStr(email).toString();
    this.efiling.forgotPassword(email, token).subscribe((result) => {

      if (!silent) {
        const message = result.isSuccess
          ? `Welcome instructions have been successfully sent to ${email}.`
          : 'There was a problem resetting the password for this user.';

        this.snack.open(message, null, { duration: 2000 });
      }
    });
  }

  refreshCompanyUsers() {
    this.getAllCompanyUsers().subscribe(response => this.companyUsers.next(response.data));
  }

}
