import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { EfilingSelect } from './components/efiling-select/efiling-select.component';
import { SelectModule } from 'ng2-select';
import { EfilingAutocompleteComponent } from './components/efiling-autocomplete/efiling-autocomplete.component';
import { CreditCardAddComponent } from './components/credit-card-add/credit-card-add.component';
import { AchAccountAddComponent } from './components/ach-account-add/ach-account-add.component';
import { MaterialModule } from './material/material.module';
import { TextMaskModule } from 'angular2-text-mask';
import { EFilingProgressComponent } from './components/efiling-progress/efiling-progress.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { EfilingErrorComponent } from './validators/efiling-error/efiling-error.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    MaterialModule,
    TextMaskModule,
    MatTableExporterModule,
  ],
  declarations: [
    SpinnerComponent,
    EfilingSelect,
    EfilingAutocompleteComponent,
    CreditCardAddComponent,
    AchAccountAddComponent,
    EFilingProgressComponent,
    EfilingErrorComponent,
    SnackBarComponent
  ],
  exports: [
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule, 
    SpinnerComponent, 

    MaterialModule,    
    FlexLayoutModule,
    EfilingSelect, 
    EfilingAutocompleteComponent, 
    CreditCardAddComponent, 
    AchAccountAddComponent,
    MatTableExporterModule,
    EfilingErrorComponent,
    TextMaskModule,
  ],
  providers: [CurrencyPipe, DatePipe],
  entryComponents: [EFilingProgressComponent, SnackBarComponent]
})
export class SharedModule { }
