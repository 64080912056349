<div class="jumbotron text-center" style="height:610px;">
  <div class="container">
    <h1>Sign in</h1>
  </div>
</div>
<div class="login-form">
  <form [formGroup]="loginForm" class="login-container" (ngSubmit)="login()">
    <div class="form-group">
      <div class="input-group">
        <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
        <input type="text" placeholder="Username (required)" formControlName="username"
               class="form-control" />
        <div *ngIf="usernameInvalid" class="help-block with-errors">
          Email is required
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
        <input type="password" placeholder="Password (required)" formControlName="password" class="form-control" />
        <div *ngIf="passwordInvalid" class="help-block with-errors">
          Password is required
        </div>
      </div>
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary login-btn btn-block">Sign in</button>
    </div>
    <div class="clearfix">
      <label class="pull-left checkbox-inline"><input type="checkbox"> Remember me</label>
      <a href="#" class="pull-right">Forgot Password?</a>
    </div>
    <div class="or-seperator"><i>or</i></div>
    <!--<p class="text-center">Login with your social media account</p>
  <div class="text-center social-btn">
    <a href="#" class="btn btn-primary"><i class="fa fa-facebook"></i>&nbsp; Facebook</a>
    <a href="#" class="btn btn-info"><i class="fa fa-twitter"></i>&nbsp; Twitter</a>
    <a href="#" class="btn btn-danger"><i class="fa fa-google"></i>&nbsp; Google</a>
  </div>-->
    <p class="text-center text-muted small">Don't have an account? <a href="#">Sign up here!</a></p>
  </form>
  
</div>

