var FusePerfectScrollbarGeometry = /** @class */ (function () {
    function FusePerfectScrollbarGeometry(x, y, w, h) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }
    return FusePerfectScrollbarGeometry;
}());
export { FusePerfectScrollbarGeometry };
var FusePerfectScrollbarPosition = /** @class */ (function () {
    function FusePerfectScrollbarPosition(x, y) {
        this.x = x;
        this.y = y;
    }
    return FusePerfectScrollbarPosition;
}());
export { FusePerfectScrollbarPosition };
