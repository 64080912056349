import * as tslib_1 from "tslib";
// taken from: https://www.npmjs.com/package/angular-cc-library/v/1.2.5, https://github.com/nogorilla/angular-cc-library/blob/master/src/credit-card.validator.ts
import { Validators } from '@angular/forms';
import { CreditCardService } from '../services/credit-card.service';
var CreditCardValidator = /** @class */ (function () {
    function CreditCardValidator() {
    }
    CreditCardValidator.validateCCNumber = function (control) {
        if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
            return { ccNumber: true };
        }
        var num = control.value.toString().replace(/\s+|-/g, '');
        if (!/^\d+$/.test(num)) {
            return { ccNumber: true };
        }
        var card = CreditCardService.cardFromNumber(num);
        if (!card) {
            return { ccNumber: true };
        }
        if (card.length.includes(num.length) && (card.luhn === false || CreditCardService.luhnCheck(num))) {
            return null;
        }
        var upperlength = card.length[card.length.length - 1];
        if (num.length > upperlength) {
            var registeredNum = num.substring(0, upperlength);
            if (CreditCardService.luhnCheck(registeredNum)) {
                return null;
            }
        }
        return { ccNumber: true };
    };
    CreditCardValidator.validateExpDate = function (control) {
        if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
            return { expDate: true };
        }
        if (typeof control.value !== 'undefined' && control.value.length >= 7) {
            var _a = tslib_1.__read(control.value.split(/[\s\/]+/, 2), 2), month = _a[0], year = _a[1], prefix = void 0;
            if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
                prefix = new Date().getFullYear();
                prefix = prefix.toString().slice(0, 2);
                year = prefix + year;
            }
            month = parseInt(month, 10).toString();
            year = parseInt(year, 10).toString();
            if (/^\d+$/.test(month) && /^\d+$/.test(year) && (month >= 1 && month <= 12)) {
                var currentTime = void 0, expiry = void 0;
                expiry = new Date(year, month);
                currentTime = new Date();
                expiry.setMonth(expiry.getMonth() - 1);
                expiry.setMonth(expiry.getMonth() + 1, 1);
                if (expiry > currentTime) {
                    return null;
                }
            }
        }
        return { expDate: true };
    };
    return CreditCardValidator;
}());
export { CreditCardValidator };
