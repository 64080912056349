import { EfilingService } from '../../../core/services/efiling.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { uscfLoginDTO } from '../../../core/models/uscfLoginDTO.model';
import { UserLoginStatus } from '../../../shared/enum';

@Component({
  selector: 'user-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {

  registrationForm: FormGroup;
  registrationSuccess = false;

  errormessage: string;

  userEntity: uscfLoginDTO = new uscfLoginDTO();
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private efilingService: EfilingService) {
  }

  ngOnInit() {
    this.registrationForm = this.fb.group({
      
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      firmName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.required],
      confirmEmail: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      memberAgreement: ['', Validators.required],
      securityQuestion: ['', Validators.required],
      securityAnswer: ['', Validators.required],
    });
  }

  get disableRegisterUser() {
    return !this.registrationForm.valid;
  }
  get firstName() {
    return this.registrationForm.get('firstName');
  }
  get lastName() {
    return this.registrationForm.get('lastName');
  }
  get firmName() {
    return this.registrationForm.get('firmName');
  }
  get phoneNumber() {
    return this.registrationForm.get('phoneNumber');
  }
  get email() {
    return this.registrationForm.get('email');
  }
  get confirmEmail() {
    return this.registrationForm.get('confirmEmail');
  }
  get password() {
    return this.registrationForm.get('password');
  }
  get confirmPassword() {
    return this.registrationForm.get('confirmPassword');
  }
  get securityQuestion() {
    return this.registrationForm.get('securityQuestion');
  }
 
  get securityAnswer() {
    return this.registrationForm.get('securityAnswer');
  }
  register() {
    this.errormessage = '';
    this.userEntity.fName = this.registrationForm.controls.firstName.value;
    this.userEntity.lName = this.registrationForm.controls.lastName.value;
    this.userEntity.firmCompany = this.registrationForm.controls.firmName.value;
    this.userEntity.phoneNumber = this.registrationForm.controls.phoneNumber.value;
    this.userEntity.email = this.registrationForm.controls.email.value;
    this.userEntity.password = this.registrationForm.controls.password.value;
    this.userEntity.username = this.registrationForm.controls.email.value;
    this.userEntity.loginStatusID = UserLoginStatus.active;
    this.userEntity.securityquestion = this.registrationForm.controls.securityQuestion.value;
    this.userEntity.securityanswer = this.registrationForm.controls.securityAnswer.value;
    this.callregisterUserAPI();
  }
  callregisterUserAPI() {
    // todo: register user
    this.efilingService.registerUser(this.userEntity)
      .subscribe(result => {
        if (result.isSuccess) {
          if (result.data != null && result.data != '') {
            this.registrationSuccess = true;
            this.registrationForm.reset();
          }
          else {
            this.errormessage = result.message;

          }
        }
        else {
          console.error(result.message);
        }
      }, error => console.error(error));
  }
}

