import { OnInit, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
var AchAccountAddComponent = /** @class */ (function () {
    function AchAccountAddComponent(efilingService, fb) {
        this.efilingService = efilingService;
        this.fb = fb;
        this.saveAchAccount = new EventEmitter();
        this.phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    Object.defineProperty(AchAccountAddComponent.prototype, "companyNameControl", {
        get: function () { return this.achAccountForm.get('company'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AchAccountAddComponent.prototype, "paymentAccountTypeId", {
        //get name(): number { return this.achAccountForm.get('name').value; }
        get: function () { return this.achAccountForm.get('paymentAccountTypeId').value; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AchAccountAddComponent.prototype, "accountNumber", {
        get: function () { return this.achAccountForm.get('accountNumber').value; },
        enumerable: true,
        configurable: true
    });
    AchAccountAddComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.efilingService.getStates()
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.stateList = result.data.states;
            }
            else
                console.error(result.message);
        }, function (error) { return console.error(error); });
        this.achAccountForm = this.fb.group({
            accountNickname: ['', Validators.required],
            paymentAccountTypeId: ['2', Validators.required],
            name: ['', Validators.required],
            company: [''],
            address: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zipCode: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            accountNumber: ['', Validators.required],
            routeNumber: ['', Validators.required],
            isTermsAuthFlag: [false, Validators.required]
        });
        this.achAccountForm.get('paymentAccountTypeId').valueChanges
            .subscribe(function (ct) {
            if (ct == 1) {
                _this.companyNameControl.setValidators([Validators.required]);
            }
            else {
                _this.companyNameControl.clearValidators();
            }
            _this.companyNameControl.updateValueAndValidity();
        });
        this.efilingService.getUserAchAccount()
            .subscribe(function (resultGet) {
            if (resultGet.isSuccess) {
                _this.achAccount = resultGet.data;
                //this.achAccountId = this.achAccount.id;
                _this.achAccountAsString = "xxxxxx" + _this.achAccount.accountNumberLast4Digits;
                _this.saveAchAccount.emit(_this.achAccount);
            }
            else
                console.error(resultGet.message);
        }, function (error) { return console.error(error); });
    };
    AchAccountAddComponent.prototype.saveAchAccountToDB = function () {
        var _this = this;
        if (!this.achAccountForm.valid) {
            return;
        }
        this.efilingService.addAchAccount(this.achAccountForm.value)
            .subscribe(function (resultAdd) {
            if (resultAdd.isSuccess) {
                //this.achAccountId = resultAdd.data as number;
                _this.efilingService.getUserAchAccount()
                    .subscribe(function (resultGet) {
                    if (resultGet.isSuccess) {
                        _this.achAccount = resultGet.data;
                        //this.achAccountId = this.achAccount.id;
                        _this.achAccountAsString = "xxxxxx" + _this.achAccount.accountNumberLast4Digits;
                        _this.saveAchAccount.emit(_this.achAccount);
                    }
                    else
                        console.error(resultGet.message);
                }, function (error) { return console.error(error); });
                //this.achAccountAsString = `XXXX-${this.accountNumber.substring(this.accountNumber.length - 4, this.accountNumber.length)}`;
            }
            else
                console.error(resultAdd.message);
        }, function (error) { return console.error(error); });
    };
    AchAccountAddComponent.prototype.removeAchAccount = function () {
        var _this = this;
        this.efilingService.deleteUserAchAccounts(this.achAccount.id)
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.achAccount = null;
                _this.saveAchAccount.emit(null);
            }
            else
                console.error(result.message);
        }, function (error) { return console.error(error); });
    };
    return AchAccountAddComponent;
}());
export { AchAccountAddComponent };
