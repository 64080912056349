var GlobalServiceDTO = /** @class */ (function () {
    function GlobalServiceDTO() {
        this.sNDTOSelected = [];
        this.sEDTOSelected = [];
    }
    GlobalServiceDTO.prototype.setNDTOSelectedVal = function (val) {
        this.sNDTOSelected = val;
    };
    GlobalServiceDTO.prototype.getNDTOSelectedVal = function () {
        return this.sNDTOSelected;
    };
    GlobalServiceDTO.prototype.setEDTOSelectedVal = function (val) {
        this.sEDTOSelected = val;
    };
    GlobalServiceDTO.prototype.getEDTOSelectedVal = function () {
        return this.sEDTOSelected;
    };
    return GlobalServiceDTO;
}());
export { GlobalServiceDTO };
