<h1 style="margin-top: 80px;">Payment Account {{paymentType}}</h1>
<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label for="credit" (click)="creditChecked()" class="btn btn-secondary active">
        <input type="radio" name="paymentType" id="credit" [checked]="paymentType === 'credit'" [value]="'credit'"> Credit
      </label>
      <label for="ach" (click)="achChecked()" class="btn btn-secondary">
        <input type="radio" name="paymentType" id="ach" [checked]="paymentType === 'ach'" [value]="'ach'"> ACH
      </label>
    </div>
  </div>
  <div class="col-md-4"></div>
</div>

<div *ngIf="paymentType === 'credit'" class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4"><credit-card-add></credit-card-add></div>
  <div class="col-md-4"></div>
</div>

<div *ngIf="paymentType === 'ach'" class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4"><ach-account-add></ach-account-add></div>
  <div class="col-md-4"></div>
</div>
