import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CreditCardValidator } from '../../../core/validators/credit-card.validator';
import { EfilingService } from '../../../core/services/efiling.service';
import { CreditCard } from '../../../core/models';
import { State } from '@app/core/models/state-list.model';
import { eventNames } from 'cluster';
import { MatRadioChange, MatCheckboxChange } from '@angular/material';
import { stream } from 'xlsx/types';

@Component({
  selector: 'credit-card-add',
  templateUrl: './credit-card-add.component.html',
  styleUrls: ['./credit-card-add.component.css']
})
export class CreditCardAddComponent implements OnInit {

  @Output() saveCreditCard = new EventEmitter<CreditCard>();
  @Output() creditCardPaymentTypeChange = new EventEmitter<number>();
  @Output() creditCardErrorMsg = new EventEmitter<boolean>();
  @Input()  filingType:string;
  isVisible:boolean=true;
  years: string[];
  stateList: State[];
  creditCard: CreditCard;
  sCreditCard: CreditCard[];
  firmCreditCard:CreditCard;
  creditCardForm: FormGroup;
  newCreditCard: boolean=true;
  existingCCSelected:boolean;
  selectedNickName:boolean=false;
  vaultIDFromNickName:number;
  newCCSelected:boolean;
  visaChecked:boolean;
  existingCardAvl:boolean=false;
  selectedVaultId:number;
  existingCard:boolean=true;
  useThisCreditCard:boolean=false;
  existingPersonalCreditCard:boolean;
  existingFirmCreditCard: boolean;
 

  checkNewCreditCard(event: any, type: string) {
    if (type != 'N') {

      this.newCreditCard=true;
      this.existingPersonalCreditCard=true;
      this.existingFirmCreditCard=true;
      this.existingCCSelected=true;
      this.newCCSelected=false;
      this.selectedVaultId=null;
      this.useThisCreditCard=false;
      
    }
    else {
      this.visaChecked=true;
      this.newCreditCard=false;
      this.existingPersonalCreditCard=false;
      this.existingFirmCreditCard=false;
      this.existingCCSelected=false;
      this.newCCSelected=true; 
      this.useThisCreditCard=false;     

     var obj = this.creditCardForm.get('paymentType').value;
     this.creditCardForm.get('firstName').setValue('');
     this.creditCardForm.get('lastName').setValue('');
     this.creditCardForm.get('nickName').setValue('');
     this.creditCardForm.get('address1').setValue('');
     this.creditCardForm.get('city').setValue('');
     this.creditCardForm.get('state').setValue('');
     this.creditCardForm.get('zipCode').setValue('');
     this.creditCardForm.get('creditCardNumber').setValue('');
     this.creditCardForm.get('securityCode').setValue('');
     this.creditCardForm.get('expirationMonth').setValue('');
     this.creditCardForm.get('expirationYear').setValue('');
     this.creditCardForm.get('rememberCreditCard').setValue(false);
     this.creditCardForm.get('paymentType').setValue('');
    
    }
    this.creditCardForm.reset();
    this.efilingService.getUserCreditCard()
    .subscribe(result => {  
      if (result.isSuccess) {
        const creditCardLoaded = result.data;
        this.sCreditCard = creditCardLoaded;
        
    }
    else
      console.error(result.message);
  }, error => console.error(error));
}
isNickNameCheck()
{
  this.vaultIDFromNickName=this.selectedVaultId;
  this.creditCard = this.creditCardForm.value;
  this.creditCard.id = this.vaultIDFromNickName;
  this.existingCard=false;
  this.saveCreditCard.emit(this.creditCard);
   var paymentTypeId=this.sCreditCard.find(x=>x.id==this.selectedVaultId).paymentTypeId;
  this.creditCardPaymentTypeChange.emit(paymentTypeId);
 
}


  get creditCardFormControl() {
    return this.creditCardForm.get('creditCardNumber');
  }
  get creditCardNumberAsString() {
    return `xxxxxx${this.creditCard.creditCardNumber.substring(this.creditCard.creditCardNumber.length - 4, this.creditCard.creditCardNumber.length)}`;
  }
  
  get creditCardNumberInvalid() {
    return (this.creditCardFormControl.touched || this.creditCardFormControl.dirty) && !this.creditCardFormControl.valid;
  }
  
  constructor(private efilingService: EfilingService, private fb: FormBuilder) {
    
  }

  ngOnInit(): void {
    // if (this.filingType == "new") {
    //   this.isVisible=false;
    // }   

    this.efilingService.getStates()
          .subscribe(result => {
            if (result.isSuccess) {
              this.stateList = result.data.states;
            }
            else
              console.error(result.message);
          }, error => console.error(error));

    this.creditCardForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      nickName: ['', Validators.required],
      address1: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      creditCardNumber: ['', [Validators.required, <any>CreditCardValidator.validateCCNumber]],
      securityCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      expirationMonth: ['', Validators.required],
      expirationYear: ['', Validators.required],
      rememberCreditCard: [false],
      paymentType:['', Validators.required]
    });

    this.efilingService.getUserCreditCard()
      .subscribe(result => {
        if (result.isSuccess) {
          this.sCreditCard = result.data;      
          if(this.sCreditCard[0])
          {
          if(this.sCreditCard[0].nickName) {
            this.existingCardAvl=false;
            this.existingPersonalCreditCard=true;
            this.existingFirmCreditCard=false;
            this.existingCCSelected=true;
            this.newCreditCard=true;
          }
          else{
            this.existingCardAvl=true;
            this.existingPersonalCreditCard=false;
            this.existingFirmCreditCard=false;
            this.newCCSelected=true;
            this.newCreditCard=false;
          }
        }
        else{
          this.existingCardAvl=true;
          this.existingPersonalCreditCard=false;
          this.existingFirmCreditCard=false;
          this.newCCSelected=true;
          this.newCreditCard=false;
        }
          
          this.creditCardForm.get('firstName').setValue(this.sCreditCard[0].firstName);
          this.creditCardForm.get('lastName').setValue(this.sCreditCard[0].lastName);
          this.creditCardForm.get('nickName').setValue(this.sCreditCard[0].nickName);
          this.creditCardForm.get('address1').setValue(this.sCreditCard[0].address1);
          this.creditCardForm.get('city').setValue(this.sCreditCard[0].city);
          this.creditCardForm.get('state').setValue(this.sCreditCard[0].state);
          this.creditCardForm.get('zipCode').setValue(this.sCreditCard[0].zipCode);
          this.creditCardForm.get('expirationMonth').setValue(this.sCreditCard[0].expirationMonth);
          this.creditCardForm.get('expirationYear').setValue(this.sCreditCard[0].expirationYear);
          this.creditCardForm.get('rememberCreditCard').setValue(true);
          this.creditCardForm.get('paymentType').setValue(this.sCreditCard[0].paymentType);

        }
        else
          console.error(result.message);
        
        this.getUserFirmCreditCards();
      }, error => console.error(error));

      

      var currentYear = new Date().getFullYear();
      this.years = [];
      for(var i=0;i<12;i++){
          this.years.push((currentYear+i).toString());
      }
     
  }
  getUserFirmCreditCards(){
    this.efilingService.getUserFirmCreditCard()
      .subscribe(result => {
        if (result.isSuccess) {
          this.firmCreditCard =  result.data as CreditCard;         
          if(this.firmCreditCard[0])
          {
            if((this.sCreditCard[0] && this.sCreditCard[0].nickName) && (this.firmCreditCard[0] && this.firmCreditCard[0].nickName)) {
              this.existingCardAvl=false;
              this.existingCCSelected=true;
              this.existingPersonalCreditCard=true;
              this.existingFirmCreditCard=true;
              this.newCCSelected=false;
              this.newCreditCard=true;
            }
            else if((!this.sCreditCard[0] || (this.sCreditCard[0] && this.sCreditCard[0].nickName ==null)) && (this.firmCreditCard[0] && this.firmCreditCard[0].nickName)){
              this.existingCardAvl=false;
              this.existingCCSelected=true;
              this.existingPersonalCreditCard=false;
              this.existingFirmCreditCard=true;
              this.newCCSelected=false;
              this.newCreditCard=true;
            }
            else{
              this.existingFirmCreditCard=false;
              this.existingPersonalCreditCard=false;
              this.existingCardAvl=true;
              this.newCCSelected=true;
              this.newCreditCard=false;
            }

        }
        else{
            this.existingFirmCreditCard=false;      
          if(this.sCreditCard[0])
          {
              if(this.sCreditCard[0].nickName) {
                this.existingCardAvl=false;
                this.existingPersonalCreditCard=true;
                this.existingCCSelected=true;
                this.newCreditCard=true;
              }
              else{
                this.existingCardAvl=true;
                this.existingPersonalCreditCard=false;
                this.newCCSelected=true;
                this.newCreditCard=false;
              }
        }
        else{
          this.existingCardAvl=true;
          this.newCCSelected=true;
          this.newCreditCard=false;
        }
          
        }
          
          this.creditCardForm.get('firstName').setValue(this.firmCreditCard.firstName);
          this.creditCardForm.get('lastName').setValue(this.firmCreditCard.lastName);
          this.creditCardForm.get('nickName').setValue(this.firmCreditCard.nickName);
          this.creditCardForm.get('address1').setValue(this.firmCreditCard.address1);
          this.creditCardForm.get('city').setValue(this.firmCreditCard.city);
          this.creditCardForm.get('state').setValue(this.firmCreditCard.state);
          this.creditCardForm.get('zipCode').setValue(this.firmCreditCard.zipCode);
          this.creditCardForm.get('expirationMonth').setValue(this.firmCreditCard.expirationMonth);
          this.creditCardForm.get('expirationYear').setValue(this.firmCreditCard.expirationYear);
          this.creditCardForm.get('rememberCreditCard').setValue(true);
          this.creditCardForm.get('paymentType').setValue(this.firmCreditCard.paymentType);

        }
        else
          console.error(result.message);
      }, error => console.error(error));
  }
  
  saveCCInVaultDB(){
    this.newCreditCard=true;
    if(this.newCCSelected){
    if (!this.creditCardForm.valid) {
      return;
    }
  
      this.efilingService.addCreditCard(this.creditCardForm.value)
        .subscribe(result => {
          if (result.isSuccess) {
            this.creditCard = this.creditCardForm.value;
            this.creditCard.id = result.data as number;
            this.saveCreditCard.emit(this.creditCard);
            this.newCCSelected=false;
          }
          else
            console.error(result.message);
        }, error => console.error(error));
    }
    this.useThisCreditCard=true;
    this.newCCSelected=false;
  }
 
 
  saveCreditCardToDB() {
    this.newCreditCard=true;
    if(this.newCCSelected){
    if (!this.creditCardForm.valid) {
      return;
    }
  
    if (this.creditCard) {
      this.efilingService.rememberCreditCard(this.creditCard.id, this.creditCardForm.get('rememberCreditCard').value as boolean)
        .subscribe(result => {
          if (result.isSuccess) {
            let creditCard = this.creditCardForm.value;
            creditCard.nickName = this.creditCard.nickName;
            creditCard.id=this.creditCard.id;
            this.saveCreditCard.emit(this.creditCard);
            this.newCCSelected=false;
          }
          else
            console.error(result.message);
        }, error => console.error(error));
    }
    else {
      this.efilingService.addCreditCard(this.creditCardForm.value)
        .subscribe(result => {
          if (result.isSuccess) {
            this.creditCard = this.creditCardForm.value;
            this.creditCard.id = result.data as number;
            this.saveCreditCard.emit(this.creditCard);
            this.newCCSelected=false;
          }
          else
            console.error(result.message);
        }, error => console.error(error));
    }
    this.useThisCreditCard=true;
    this.creditCardForm.value.rememberCreditCard=false;
    this.creditCard = this.creditCardForm.value;
    this.creditCard.id = this.vaultIDFromNickName;
    this.saveCreditCard.emit(this.creditCard);
  }
  else{
    this.creditCard = this.creditCardForm.value;
    this.creditCard.id = this.vaultIDFromNickName;
    this.saveCreditCard.emit(this.creditCard);
   
  }
}

  removeCreditCard(): void{
    this.creditCard = null;
    this.saveCreditCard.emit(null);
  }

  hideMessage() {
    //this.message="";
  }
  
  onCreditCardToggleChange(event: MatRadioChange): void{
    const val = event.value;
    this.existingCard=false;
    this.creditCardForm.get('paymentType').setValue(val);
    this.creditCardPaymentTypeChange.emit(val);
    this.creditCard = this.creditCardForm.value;
    this.creditCard.paymentType=val;
    this.creditCard.paymentTypeId=val;
    this.creditCardErrorMsg.emit(this.creditCardForm.valid);
    this.saveCreditCard.emit(this.creditCard);
    
  }
}
