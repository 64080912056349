/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./verify-user.component";
import * as i2 from "@angular/router";
var styles_VerifyUserComponent = [];
var RenderType_VerifyUserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VerifyUserComponent, data: {} });
export { RenderType_VerifyUserComponent as RenderType_VerifyUserComponent };
export function View_VerifyUserComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["verifying..."]))], null, null); }
export function View_VerifyUserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "redirect", [], null, null, null, View_VerifyUserComponent_0, RenderType_VerifyUserComponent)), i0.ɵdid(1, 114688, null, 0, i1.VerifyUserComponent, [i2.Router, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyUserComponentNgFactory = i0.ɵccf("redirect", i1.VerifyUserComponent, View_VerifyUserComponent_Host_0, {}, {}, []);
export { VerifyUserComponentNgFactory as VerifyUserComponentNgFactory };
