import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(fb, efilingService) {
        this.fb = fb;
        this.efilingService = efilingService;
        this.submitted = false;
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.forgotPwdForm = this.fb.group({
            username: ['', Validators.required]
        });
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "username", {
        get: function () {
            return this.forgotPwdForm.get('username');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForgotPasswordComponent.prototype, "usernameInvalid", {
        get: function () {
            return (this.username.touched || this.username.dirty) && !this.username.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForgotPasswordComponent.prototype, "disableSubmit", {
        get: function () {
            return !this.forgotPwdForm.valid;
        },
        enumerable: true,
        configurable: true
    });
    ForgotPasswordComponent.prototype.sendEmail = function () {
        this.submitted = true;
        // todo: send email logic here
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
