<div *ngIf="!this.creditCard">

  <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-radio-group 
          class="credit-card-radio-group">
          <mat-radio-button value="2" class="credit-card-radio-button" [checked]="newCCSelected"  (change)="checkNewCreditCard($event, 'N')">New Card</mat-radio-button>
   
          <mat-radio-button  [disabled]="existingCardAvl" value="3" class="credit-card-radio-button" [checked]="existingCCSelected" (change)="checkNewCreditCard($event,'E')">Existing Card</mat-radio-button>
  
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div *ngIf="!this.existingCard">

    <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <mat-radio-group 
            class="credit-card-radio-group">
            <mat-radio-button value="2" class="credit-card-radio-button" [checked]="newCCSelected"  (change)="checkNewCreditCard($event, 'N')">New Card</mat-radio-button>
     
            <mat-radio-button  [disabled]="existingCardAvl" value="3" class="credit-card-radio-button" [checked]="existingCCSelected" (change)="checkNewCreditCard($event,'E')">Existing Card</mat-radio-button>
    
          </mat-radio-group>
        </div>
      </div>
    </div>
<div *ngIf="!this.newCreditCard">

<div fxLayout="column" fxLayoutAlign="center stretch">
  <form [formGroup]="creditCardForm">
      <!-- (ngSubmit)="saveCreditCardToDB()" -->
    <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <!-- <mat-button-toggle-group #group="matButtonToggleGroup" [value]="this.creditCardForm.get('paymentTypeId')?.value?.toString()" (change)="onCreditCardToggleChange(group.value)">
          <mat-button-toggle value="2">
            Visa
          </mat-button-toggle>
          <mat-button-toggle value="3">
            Mastercard
          </mat-button-toggle>
          <mat-button-toggle value="5">
              American Express
          </mat-button-toggle>
          [checked]="visaChecked"
        </mat-button-toggle-group> -->
        <mat-radio-group [value]="this.creditCardForm.get('paymentType')?.value?.toString()" (change)="onCreditCardToggleChange($event)"
          class="credit-card-radio-group" formControlName="paymentType">
          <mat-radio-button value="2"  class="credit-card-radio-button">Visa</mat-radio-button>
          <mat-radio-button value="3" class="credit-card-radio-button">Mastercard</mat-radio-button>
          <mat-radio-button value="5" class="credit-card-radio-button">American Express</mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="creditCardForm.get('paymentType').hasError('required')">
          Payment Type is required
        </mat-error>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch">

        <mat-form-field fxFlex="30" appearance="outline">
            <mat-label>Nick Name *</mat-label>
            <input matInput formControlName="nickName" (keypress)="hideMessage()">
            <mat-error *ngIf="creditCardForm.get('nickName').hasError('required')">
                Nick Name is required
            </mat-error>
        </mat-form-field> 
        
      <mat-form-field fxFlex="30" appearance="outline">
        <mat-label>First Name *</mat-label>
        <input matInput formControlName="firstName" (keypress)="hideMessage()">
        <mat-error *ngIf="creditCardForm.get('firstName').hasError('required')">
            First Name is required
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>Last Name *</mat-label>
          <input matInput formControlName="lastName" (keypress)="hideMessage()">
          <mat-error *ngIf="creditCardForm.get('lastName').hasError('required')">
              Last Name is required
          </mat-error>
      </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="outline">
            <mat-label>Street *</mat-label>
            <input matInput formControlName="address1" (keypress)="hideMessage()">
            <mat-error *ngIf="creditCardForm.get('address1').hasError('required')">
                Street Address is required
            </mat-error>
        </mat-form-field>
    </div>
      
    <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-form-field fxFlex="30" appearance="outline">
            <mat-label>City *</mat-label>
            <input matInput formControlName="city" (keypress)="hideMessage()">
            <mat-error *ngIf="creditCardForm.get('city').hasError('required')">
                City is required
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>State *</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let list of stateList" value={{list.code}}>
            {{list.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="creditCardForm.get('state').hasError('required')">
              State is required
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" appearance="outline">
            <mat-label>Zip Code *</mat-label>
            <input matInput formControlName="zipCode" (keypress)="hideMessage()">
            <mat-error *ngIf="creditCardForm.get('zipCode').hasError('required')">
                Zip Code is required
            </mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="outline">
            <mat-label>Credit Card Number *</mat-label>
            <input matInput formControlName="creditCardNumber" (keypress)="hideMessage()">
            <mat-error *ngIf="creditCardForm.get('creditCardNumber').hasError('required')">
                Credit Card Number is required
            </mat-error>
            <mat-error *ngIf="creditCardNumberInvalid">
                Credit Card Number is not valid
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-form-field fxFlex="30" appearance="outline">
            <mat-label>Security Code *</mat-label>
            <input matInput formControlName="securityCode" (keypress)="hideMessage()">
            <mat-error *ngIf="creditCardForm.get('securityCode').hasError('required')">
                Security Code is required
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>Expiry Month *</mat-label>
          <mat-select formControlName="expirationMonth">
            <mat-option value="1">01</mat-option>
            <mat-option value="2">02</mat-option>
            <mat-option value="3">03</mat-option>
            <mat-option value="4">04</mat-option>
            <mat-option value="5">05</mat-option>
            <mat-option value="6">06</mat-option>
            <mat-option value="7">07</mat-option>
            <mat-option value="8">08</mat-option>
            <mat-option value="9">09</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="11">11</mat-option>
            <mat-option value="12">12</mat-option>
          </mat-select>
          <mat-error *ngIf="creditCardForm.get('expirationMonth').hasError('required')">
              Expiration Month is required
          </mat-error>
        </mat-form-field>


        <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>Expiry Year *</mat-label>
          <mat-select formControlName="expirationYear">
            <mat-option *ngFor="let yr of this.years" [value]="yr">
              {{yr}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="creditCardForm.get('expirationYear').hasError('required')">
              Expiration Year is required
          </mat-error>
        </mat-form-field>

    </div>

    <div>
        <mat-checkbox formControlName="rememberCreditCard">
          Save my billing address information <em>(the credit card information will not be stored)</em>
        </mat-checkbox>
    </div>

    <div>
      <mat-error *ngIf="(this.creditCardForm.touched || this.creditCardForm.dirty) && !this.creditCardForm.valid">
          Please fill in all the required fields to continue
      </mat-error>
      <!-- <mat-error *ngIf="selectExistingCard">
          * Please select at least one existing credit card
      </mat-error> -->
    </div>

    <div>
      <button class="mat-raised-button mat-accent" (click)="saveCCInVaultDB()" *ngIf="isVisible" type="submit">Save This Card</button>
    </div>

  </form>
</div>
</div>


<div *ngIf="this.useThisCreditCard">
<div fxLayout="column" fxLayoutAlign="space-around start">
  <div>
    <h3><strong>Credit card</strong></h3>
  </div>
  <div>
    <div>
      <h3>Nick Name: <strong>{{this.creditCard.nickName}}</strong> (Your billing information saved successfully)</h3>
    </div>
  </div>
</div>
</div>

  <div  *ngIf="this.existingPersonalCreditCard"  fxLayout="column" fxLayoutAlign="start start">
      <mat-card class="mg-top-mat-header">
         <div>
            <mat-card-header class="header accent top-padding">
                <mat-card-title>My Credit Card(s)</mat-card-title>   
            </mat-card-header>
           <mat-card-content >
             <mat-radio-group aria-labelledby="example-radio-group-label" class = "example-radio-group" [(ngModel)] = "selectedVaultId" (change)='isNickNameCheck()'>
             <mat-radio-button class = "example-radio-button mg-font"
                              *ngFor = "let ccl of sCreditCard" [value] = "ccl.id">
                             <strong> {{ccl.nickName}}</strong>
              </mat-radio-button>
              </mat-radio-group>

          </mat-card-content>
         </div>
        </mat-card>
     </div>
     <div  *ngIf="this.existingFirmCreditCard"  fxLayout="column" fxLayoutAlign="start start">
      <mat-card class="mg-top-mat-header">
         <div>
            <mat-card-header class="header accent top-padding" >            
                <mat-card-title>Firm/Orginization Credit Card(s)</mat-card-title>   
            </mat-card-header>
           <mat-card-content >
             <mat-radio-group aria-labelledby="example-radio-group-label" class = "example-radio-group" [(ngModel)] = "selectedVaultId" (change)='isNickNameCheck()'>
             <mat-radio-button class = "example-radio-button mg-font"
                              *ngFor = "let fc of firmCreditCard" [value] = "fc.id">
                             <strong> {{fc.nickName}}</strong>
              </mat-radio-button>
              </mat-radio-group>

          </mat-card-content>
         </div>
        </mat-card>
     </div>

