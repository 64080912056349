var MailConfirmComponent = /** @class */ (function () {
    function MailConfirmComponent(_fuseConfigService, route) {
        var _this = this;
        this._fuseConfigService = _fuseConfigService;
        this.route = route;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.route.queryParams.subscribe(function (params) {
            _this.email = params['email'];
        });
    }
    return MailConfirmComponent;
}());
export { MailConfirmComponent };
