import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaymentAccountComponent } from './payment-account/payment-account.component';
export var userRoutes = [
    {
        path: 'user',
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'registration',
                component: RegistrationComponent
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },
            {
                path: 'payment-account',
                component: PaymentAccountComponent
            }
        ]
    },
];
var UserRoutingModule = /** @class */ (function () {
    function UserRoutingModule() {
    }
    return UserRoutingModule;
}());
export { UserRoutingModule };
export var routedUserComponents = [LoginComponent, RegistrationComponent, ForgotPasswordComponent, PaymentAccountComponent];
