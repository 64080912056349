import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EfilingService } from '../../../core/services/efiling.service';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, delay, catchError, finalize } from 'rxjs/operators';
// import { of } from 'rxjs/observable/of';
import { of } from 'rxjs';

@Component({
  selector: 'user-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  error: string;
  isLoading: boolean;
  loginForm: FormGroup;
  returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private efilingService: EfilingService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.efilingService.loginOrangeCounty()
      .subscribe(result => {
        if (result.isSuccess && result.data) {
          this.setSession(result.data);
          this.router.navigateByUrl(this.returnUrl);
        }
        else {
          console.error(result.message);
        }
      }, error => console.error(error));

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  
  get disableLogin() {
    return !this.loginForm.valid;
  }
  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }
  get usernameInvalid() {
    return (this.username.touched || this.username.dirty) && !this.username.valid;
  }
  get passwordInvalid() {
    return (this.password.touched || this.password.dirty) && !this.password.valid;
  }

  private setSession(authResult: any) {
    // const expiresAt = moment().add(authResult.expiresIn, 'second');
    localStorage.setItem('bearerToken', authResult.bearerToken);
    // localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
  }     

  login() {
    this.efilingService.login(this.username.value, this.password.value)
      .subscribe(result => {
        if (result.isSuccess) {
          this.setSession(result.data);
          this.router.navigateByUrl(this.returnUrl);
        }
        else {
          console.error(result.message);
        }
      }, error => console.error(error));
  }
}

