import { EfilingService } from '../../../core/services/efiling.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { transition } from '@angular/animations';

@Component({
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  forgotPwdForm: FormGroup;
  submitted = false;
  
  constructor(
    private fb: FormBuilder,
    private efilingService: EfilingService) {
  }
  
  ngOnInit() {
    this.forgotPwdForm = this.fb.group({
      username: ['', Validators.required]
    });
  }
  
  get username() {
    return this.forgotPwdForm.get('username');
  }

  get usernameInvalid() {
    return (this.username.touched || this.username.dirty) && !this.username.valid;
  }

  get disableSubmit() {
    return !this.forgotPwdForm.valid;
  }
  
  sendEmail() {
    this.submitted = true;
    // todo: send email logic here
  }
}

