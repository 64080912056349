import { OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { uscfLoginDTO } from '@app/core/models/uscfLoginDTO.model';
import { UserLoginStatus } from '@app/shared/enum';
import { MatDialog } from '@angular/material/dialog';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(_fuseConfigService, _formBuilder, router, efilingService, dialog) {
        this._fuseConfigService = _fuseConfigService;
        this._formBuilder = _formBuilder;
        this.router = router;
        this.efilingService = efilingService;
        this.dialog = dialog;
        this.userEntity = new uscfLoginDTO();
        this.isFirmVisible = false;
        this.phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.isRegisterButtonClicked = false;
        this.registrationTypeList = [
            { id: 0, value: 'I am registering as Self-Represented or Sole Practitioner' },
            { id: 1, value: 'I am registering as a Firm or Organization' }
        ];
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registerForm = this._formBuilder.group({
            registrationType: ['', Validators.required],
            fname: ['', Validators.required],
            lname: ['', Validators.required],
            firmName: [''],
            email: ['', [Validators.required, Validators.email]],
            /*    emailConfirm   : ['', [Validators.required, Validators.email,confirmEmailValidator]], */
            password: ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            terms: ['', Validators.required]
        });
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.registerForm.get('passwordConfirm').updateValueAndValidity();
        });
    };
    Object.defineProperty(RegisterComponent.prototype, "disableRegisterButton", {
        get: function () {
            return this.registerForm.invalid || this.isRegisterButtonClicked;
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.registrationChange = function (id) {
        var firmName = this.registerForm.get('firmName');
        if (id == 1) {
            this.isFirmVisible = true;
            firmName.setValidators([Validators.required]);
        }
        else {
            this.isFirmVisible = false;
            firmName.clearValidators();
        }
        firmName.updateValueAndValidity();
    };
    RegisterComponent.prototype.register = function () {
        this.isRegisterButtonClicked = true;
        this.errormessage = '';
        this.userEntity.fName = this.registerForm.controls.fname.value;
        this.userEntity.lName = this.registerForm.controls.lname.value;
        this.userEntity.firmCompany = this.registerForm.controls.firmName.value;
        this.userEntity.email = this.registerForm.controls.email.value;
        this.userEntity.password = this.registerForm.controls.password.value;
        this.userEntity.username = this.registerForm.controls.email.value;
        this.userEntity.loginStatusID = UserLoginStatus.inactive;
        this.userEntity.registrationType = this.registerForm.controls.registrationType.value;
        this.callregisterUserAPI();
        // this.openDialog();
        // this.goToConfirmPage();
    };
    RegisterComponent.prototype.callregisterUserAPI = function () {
        var _this = this;
        this.efilingService.registerUser(this.userEntity)
            .subscribe(function (result) {
            if (result.isSuccess) {
                if (result.data != null && result.data != '') {
                    _this.registerForm.reset();
                    _this.goToConfirmPage();
                }
                else {
                    _this.errormessage = result.message;
                }
            }
            else {
                console.error(result.message);
            }
            _this.isRegisterButtonClicked = false;
        }, function (error) {
            console.error(error);
            _this.isRegisterButtonClicked = false;
        });
    };
    RegisterComponent.prototype.goToConfirmPage = function () {
        var navigationExtras = {
            queryParams: {
                email: this.userEntity.email
            }
        };
        this.router.navigate(['/auth/mail-confirm'], navigationExtras);
    };
    RegisterComponent.prototype.openTermsAndConditonDialog = function () {
        var dialogRef = this.dialog.open(TermAndConditionDialog, {
            width: '650px',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed');
        });
    };
    /**
     * On destroy
     */
    RegisterComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return RegisterComponent;
}());
export { RegisterComponent };
var TermAndConditionDialog = /** @class */ (function () {
    function TermAndConditionDialog() {
    }
    return TermAndConditionDialog;
}());
export { TermAndConditionDialog };
/**
 * Confirm email validator
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export var confirmEmailValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var email = control.parent.get('email');
    var emailConfirm = control.parent.get('emailConfirm');
    if (!email || !emailConfirm) {
        return null;
    }
    if (emailConfirm.value === '') {
        return null;
    }
    if (email.value === emailConfirm.value) {
        return null;
    }
    return { emailNotMatching: true };
};
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export var confirmPasswordValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var password = control.parent.get('password');
    var passwordConfirm = control.parent.get('passwordConfirm');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (passwordConfirm.value === '') {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { passwordsNotMatching: true };
};
