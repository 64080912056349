<div id="register" fxLayout="column">

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="register-form" [@animate]="{value:'*',params:{duration:'200ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/alnlogo.png">
            </div>

            <div class="title">CREATE AN ACCOUNT</div>

            <div class="message">Transactions are $9.95 per filing.</div>
            <div class="message">Email us at <a href='mailto:aperea@alncorp.com'>aperea@alncorp.com</a> for volume discounts as well as form sets in fillable MS Word and PDF formats</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    formControlName="registrationType">
                    <mat-radio-button class="example-radio-button" (change)="registrationChange(regType.id)" *ngFor="let regType of registrationTypeList" [value]="regType.value">
                      {{regType.value}}
                    </mat-radio-button>
                  </mat-radio-group> 
                <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="fname">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>First Name is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lname">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>Last Name is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="isFirmVisible">
                    <mat-label>Firm Name</mat-label>
                    <input matInput formControlName="firmName">
                    <mat-icon matSuffix class="secondary-text">business</mat-icon>
                    <mat-error>Firm Name is required</mat-error>
                </mat-form-field>             


                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <!--  <mat-form-field appearance="outline">
                    <mat-label>Confirm Email</mat-label>
                    <input matInput formControlName="emailConfirm">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('emailConfirm').hasError('required')">
                        Confirm Email is required
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('emailConfirm').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('emailConfirm').hasError('required') && !registerForm.get('emailConfirm').hasError('email') &&
                                       registerForm.get('emailConfirm').hasError('emailNotMatching')">
                        Email must match
                    </mat-error>
                </mat-form-field> -->

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('password').hasError('required')">
                        Password is required
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('password').hasError('minlength') && !registerForm.get('password').hasError('required')">
                        Password should contain 6 characters        
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Passwords must match
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" formControlName="terms" aria-label="I read and accept" required>
                        <span>I read and accept terms and conditions</span>
                    </mat-checkbox>
                    <a href="javascript:void(0);" (click)="openTermsAndConditonDialog()" >terms and conditions</a>
                </div>
                <button mat-raised-button color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                    [disabled]="disableRegisterButton" (click)="register()">
                    CREATE AN ACCOUNT
                </button>
                <mat-error>{{this.errormessage}}</mat-error>
            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Already have an account?</span>
                <a class="link" [routerLink]="'/auth/login'">Login</a>
            </div>

        </div>

    </div>
</div>