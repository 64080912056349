import { SampleComponent } from './sample.component';
var routes = [
    {
        path: 'sample',
        component: SampleComponent
    }
];
var SampleModule = /** @class */ (function () {
    function SampleModule() {
    }
    return SampleModule;
}());
export { SampleModule };
