import { Component, ViewEncapsulation, OnInit} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { EfilingService } from '@app/core/services/efiling.service';

@Component({
    selector     : 'activate-account',
    templateUrl  : './activate-account.component.html',
    styleUrls    : ['./activate-account.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ActivateAccountComponent
{
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    id: string;
    activationMessage: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private efilingService: EfilingService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        
        this.id = this.route.snapshot.queryParams['id'];

        this.efilingService.activateAccount(this.id)
            .subscribe(result => {
                if (result.isSuccess) {
                    this.activationMessage = "Your account has been successfully activated!";
                }
                else
                {
                    this.activationMessage = result.message;
                }
            }, error => { 
                this.activationMessage = error;
                console.error(error);
            });
    }
}
