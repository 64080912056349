import { navigation } from 'app/navigation/navigation';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../@fuse/components/navigation/navigation.service";
var BASE_URL_WEBAPI = environment.webApiBaseUri;
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, router, authentication, fuseNavigation) {
        this.http = http;
        this.router = router;
        this.authentication = authentication;
        this.fuseNavigation = fuseNavigation;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.login = function (username, password) {
        var _this = this;
        return this.http.post(BASE_URL_WEBAPI + "/users", { username: username, password: password })
            .pipe(map(function (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            _this.RegisterCurrentUserSetup(response);
            //redirect an Admin to this page
            if (_this.isAdmin('SecurityAdmin')) {
                _this.router.navigate(['/user-management']);
            }
            return response;
        }));
    };
    AuthenticationService.prototype.loginFromOc = function (ocToLascId) {
        var _this = this;
        return this.http.post(BASE_URL_WEBAPI + "/users/orange-county-session-mapping", { value: ocToLascId })
            .pipe(map(function (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            _this.RegisterCurrentUserSetup(response);
            return response.isSuccess;
        }));
    };
    AuthenticationService.prototype.RegisterCurrentUserSetup = function (response) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        this.currentUserSubject.next(response.data);
        this.setupMenu();
    };
    AuthenticationService.prototype.setupMenu = function () {
        // Get default navigation
        var menu = this.getUserNavigation(navigation);
        this.fuseNavigation.unregister('main');
        this.fuseNavigation.register('main', menu);
        this.fuseNavigation.setCurrentNavigation('main');
    };
    AuthenticationService.prototype.getUserNavigation = function (items) {
        if (this.isAdmin('SecurityAdmin')) {
            items[0]
                .children.find(function (item) { return item.id === 'aln-productmanagement'; })
                .children.find(function (child) { return child.id === 'aln-Admin'; })
                .children.find(function (grandChild) { return grandChild.id === 'administration'; })
                .hidden = false;
            items[0]
                .children.find(function (item) { return item.id === 'aln-productmanagement'; })
                .children.find(function (child) { return child.id === 'aln-Admin'; })
                .hidden = false;
            items[0]
                .children.find(function (item) { return item.id === 'aln-productmanagement'; })
                .children.find(function (child) { return child.id === 'aln-Admin'; })
                .children.find(function (grandChild) { return grandChild.id === 'settings'; })
                .hidden = false;
            items[0]
                .children.find(function (item) { return item.id === 'aln-productmanagement'; })
                .children.find(function (child) { return child.id === 'aln-Admin'; })
                .children.find(function (grandChild) { return grandChild.id === 'order'; })
                .hidden = false;
            items[0]
                .children.find(function (item) { return item.id === 'aln-productmanagement'; })
                .children.find(function (child) { return child.id === 'aln-Admin'; })
                .children.find(function (grandChild) { return grandChild.id === 'firmPayment'; })
                .hidden = false;
        }
        // if (this.isAdmin('BillingManager')) {
        //     items[0]
        //         .children.find(item => item.id === 'aln-productmanagement')
        //         .children.find(child => child.id === 'aln-Admin')
        //         .children.find(grandChild => grandChild.id === 'billing')
        //         .hidden = false;
        // }
        return items.slice();
    };
    AuthenticationService.prototype.isAdmin = function (adminType) {
        if (adminType === void 0) { adminType = 'SecurityAdmin'; }
        if (this.currentUserSubject.value) {
            var applicationRoles = this.currentUserSubject.value.applicationRoles;
            if (applicationRoles) {
                var roles = applicationRoles.find(function (role) { return role.rolecode === adminType; });
                return !!roles;
            }
        }
        return false;
    };
    AuthenticationService.prototype.logout = function () {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(AuthenticationService), i0.ɵɵinject(i3.FuseNavigationService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
