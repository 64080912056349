import { OnInit } from '@angular/core';
import { environment } from '@env/environment';
var AppGatewayComponent = /** @class */ (function () {
    function AppGatewayComponent(route, authenticationService) {
        this.route = route;
        this.authenticationService = authenticationService;
    }
    AppGatewayComponent.prototype.ngOnInit = function () {
        this.currentUser = this.authenticationService.currentUserValue;
        var disableGuardForCentralAppsLink = 'disableGuard=true';
        if (this.route.snapshot.params['landingpage'] === 'mail') {
            window.location.href = environment.centralAppBaseUri + "/apps/appgateway/" + this.currentUser.userId + "/mail?" + disableGuardForCentralAppsLink;
        }
        else if (this.route.snapshot.params['landingpage'] === 'calendar') {
            window.location.href = environment.centralAppBaseUri + "/apps/appgateway/" + this.currentUser.userId + "/calendar?" + disableGuardForCentralAppsLink;
        }
    };
    return AppGatewayComponent;
}());
export { AppGatewayComponent };
