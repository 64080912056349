import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditCardValidator } from '../../../core/validators/credit-card.validator';
import { MatRadioChange } from '@angular/material';
var CreditCardAddComponent = /** @class */ (function () {
    function CreditCardAddComponent(efilingService, fb) {
        this.efilingService = efilingService;
        this.fb = fb;
        this.saveCreditCard = new EventEmitter();
        this.creditCardPaymentTypeChange = new EventEmitter();
        this.creditCardErrorMsg = new EventEmitter();
        this.isVisible = true;
        this.newCreditCard = true;
        this.selectedNickName = false;
        this.existingCardAvl = false;
        this.existingCard = true;
        this.useThisCreditCard = false;
    }
    CreditCardAddComponent.prototype.checkNewCreditCard = function (event, type) {
        var _this = this;
        if (type != 'N') {
            this.newCreditCard = true;
            this.existingPersonalCreditCard = true;
            this.existingFirmCreditCard = true;
            this.existingCCSelected = true;
            this.newCCSelected = false;
            this.selectedVaultId = null;
            this.useThisCreditCard = false;
        }
        else {
            this.visaChecked = true;
            this.newCreditCard = false;
            this.existingPersonalCreditCard = false;
            this.existingFirmCreditCard = false;
            this.existingCCSelected = false;
            this.newCCSelected = true;
            this.useThisCreditCard = false;
            var obj = this.creditCardForm.get('paymentType').value;
            this.creditCardForm.get('firstName').setValue('');
            this.creditCardForm.get('lastName').setValue('');
            this.creditCardForm.get('nickName').setValue('');
            this.creditCardForm.get('address1').setValue('');
            this.creditCardForm.get('city').setValue('');
            this.creditCardForm.get('state').setValue('');
            this.creditCardForm.get('zipCode').setValue('');
            this.creditCardForm.get('creditCardNumber').setValue('');
            this.creditCardForm.get('securityCode').setValue('');
            this.creditCardForm.get('expirationMonth').setValue('');
            this.creditCardForm.get('expirationYear').setValue('');
            this.creditCardForm.get('rememberCreditCard').setValue(false);
            this.creditCardForm.get('paymentType').setValue('');
        }
        this.creditCardForm.reset();
        this.efilingService.getUserCreditCard()
            .subscribe(function (result) {
            if (result.isSuccess) {
                var creditCardLoaded = result.data;
                _this.sCreditCard = creditCardLoaded;
            }
            else
                console.error(result.message);
        }, function (error) { return console.error(error); });
    };
    CreditCardAddComponent.prototype.isNickNameCheck = function () {
        var _this = this;
        this.vaultIDFromNickName = this.selectedVaultId;
        this.creditCard = this.creditCardForm.value;
        this.creditCard.id = this.vaultIDFromNickName;
        this.existingCard = false;
        this.saveCreditCard.emit(this.creditCard);
        var paymentTypeId = this.sCreditCard.find(function (x) { return x.id == _this.selectedVaultId; }).paymentTypeId;
        this.creditCardPaymentTypeChange.emit(paymentTypeId);
    };
    Object.defineProperty(CreditCardAddComponent.prototype, "creditCardFormControl", {
        get: function () {
            return this.creditCardForm.get('creditCardNumber');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCardAddComponent.prototype, "creditCardNumberAsString", {
        get: function () {
            return "xxxxxx" + this.creditCard.creditCardNumber.substring(this.creditCard.creditCardNumber.length - 4, this.creditCard.creditCardNumber.length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCardAddComponent.prototype, "creditCardNumberInvalid", {
        get: function () {
            return (this.creditCardFormControl.touched || this.creditCardFormControl.dirty) && !this.creditCardFormControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    CreditCardAddComponent.prototype.ngOnInit = function () {
        // if (this.filingType == "new") {
        //   this.isVisible=false;
        // }   
        var _this = this;
        this.efilingService.getStates()
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.stateList = result.data.states;
            }
            else
                console.error(result.message);
        }, function (error) { return console.error(error); });
        this.creditCardForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            nickName: ['', Validators.required],
            address1: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zipCode: ['', Validators.required],
            creditCardNumber: ['', [Validators.required, CreditCardValidator.validateCCNumber]],
            securityCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
            expirationMonth: ['', Validators.required],
            expirationYear: ['', Validators.required],
            rememberCreditCard: [false],
            paymentType: ['', Validators.required]
        });
        this.efilingService.getUserCreditCard()
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.sCreditCard = result.data;
                if (_this.sCreditCard[0]) {
                    if (_this.sCreditCard[0].nickName) {
                        _this.existingCardAvl = false;
                        _this.existingPersonalCreditCard = true;
                        _this.existingFirmCreditCard = false;
                        _this.existingCCSelected = true;
                        _this.newCreditCard = true;
                    }
                    else {
                        _this.existingCardAvl = true;
                        _this.existingPersonalCreditCard = false;
                        _this.existingFirmCreditCard = false;
                        _this.newCCSelected = true;
                        _this.newCreditCard = false;
                    }
                }
                else {
                    _this.existingCardAvl = true;
                    _this.existingPersonalCreditCard = false;
                    _this.existingFirmCreditCard = false;
                    _this.newCCSelected = true;
                    _this.newCreditCard = false;
                }
                _this.creditCardForm.get('firstName').setValue(_this.sCreditCard[0].firstName);
                _this.creditCardForm.get('lastName').setValue(_this.sCreditCard[0].lastName);
                _this.creditCardForm.get('nickName').setValue(_this.sCreditCard[0].nickName);
                _this.creditCardForm.get('address1').setValue(_this.sCreditCard[0].address1);
                _this.creditCardForm.get('city').setValue(_this.sCreditCard[0].city);
                _this.creditCardForm.get('state').setValue(_this.sCreditCard[0].state);
                _this.creditCardForm.get('zipCode').setValue(_this.sCreditCard[0].zipCode);
                _this.creditCardForm.get('expirationMonth').setValue(_this.sCreditCard[0].expirationMonth);
                _this.creditCardForm.get('expirationYear').setValue(_this.sCreditCard[0].expirationYear);
                _this.creditCardForm.get('rememberCreditCard').setValue(true);
                _this.creditCardForm.get('paymentType').setValue(_this.sCreditCard[0].paymentType);
            }
            else
                console.error(result.message);
            _this.getUserFirmCreditCards();
        }, function (error) { return console.error(error); });
        var currentYear = new Date().getFullYear();
        this.years = [];
        for (var i = 0; i < 12; i++) {
            this.years.push((currentYear + i).toString());
        }
    };
    CreditCardAddComponent.prototype.getUserFirmCreditCards = function () {
        var _this = this;
        this.efilingService.getUserFirmCreditCard()
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.firmCreditCard = result.data;
                if (_this.firmCreditCard[0]) {
                    if ((_this.sCreditCard[0] && _this.sCreditCard[0].nickName) && (_this.firmCreditCard[0] && _this.firmCreditCard[0].nickName)) {
                        _this.existingCardAvl = false;
                        _this.existingCCSelected = true;
                        _this.existingPersonalCreditCard = true;
                        _this.existingFirmCreditCard = true;
                        _this.newCCSelected = false;
                        _this.newCreditCard = true;
                    }
                    else if ((!_this.sCreditCard[0] || (_this.sCreditCard[0] && _this.sCreditCard[0].nickName == null)) && (_this.firmCreditCard[0] && _this.firmCreditCard[0].nickName)) {
                        _this.existingCardAvl = false;
                        _this.existingCCSelected = true;
                        _this.existingPersonalCreditCard = false;
                        _this.existingFirmCreditCard = true;
                        _this.newCCSelected = false;
                        _this.newCreditCard = true;
                    }
                    else {
                        _this.existingFirmCreditCard = false;
                        _this.existingPersonalCreditCard = false;
                        _this.existingCardAvl = true;
                        _this.newCCSelected = true;
                        _this.newCreditCard = false;
                    }
                }
                else {
                    _this.existingFirmCreditCard = false;
                    if (_this.sCreditCard[0]) {
                        if (_this.sCreditCard[0].nickName) {
                            _this.existingCardAvl = false;
                            _this.existingPersonalCreditCard = true;
                            _this.existingCCSelected = true;
                            _this.newCreditCard = true;
                        }
                        else {
                            _this.existingCardAvl = true;
                            _this.existingPersonalCreditCard = false;
                            _this.newCCSelected = true;
                            _this.newCreditCard = false;
                        }
                    }
                    else {
                        _this.existingCardAvl = true;
                        _this.newCCSelected = true;
                        _this.newCreditCard = false;
                    }
                }
                _this.creditCardForm.get('firstName').setValue(_this.firmCreditCard.firstName);
                _this.creditCardForm.get('lastName').setValue(_this.firmCreditCard.lastName);
                _this.creditCardForm.get('nickName').setValue(_this.firmCreditCard.nickName);
                _this.creditCardForm.get('address1').setValue(_this.firmCreditCard.address1);
                _this.creditCardForm.get('city').setValue(_this.firmCreditCard.city);
                _this.creditCardForm.get('state').setValue(_this.firmCreditCard.state);
                _this.creditCardForm.get('zipCode').setValue(_this.firmCreditCard.zipCode);
                _this.creditCardForm.get('expirationMonth').setValue(_this.firmCreditCard.expirationMonth);
                _this.creditCardForm.get('expirationYear').setValue(_this.firmCreditCard.expirationYear);
                _this.creditCardForm.get('rememberCreditCard').setValue(true);
                _this.creditCardForm.get('paymentType').setValue(_this.firmCreditCard.paymentType);
            }
            else
                console.error(result.message);
        }, function (error) { return console.error(error); });
    };
    CreditCardAddComponent.prototype.saveCCInVaultDB = function () {
        var _this = this;
        this.newCreditCard = true;
        if (this.newCCSelected) {
            if (!this.creditCardForm.valid) {
                return;
            }
            this.efilingService.addCreditCard(this.creditCardForm.value)
                .subscribe(function (result) {
                if (result.isSuccess) {
                    _this.creditCard = _this.creditCardForm.value;
                    _this.creditCard.id = result.data;
                    _this.saveCreditCard.emit(_this.creditCard);
                    _this.newCCSelected = false;
                }
                else
                    console.error(result.message);
            }, function (error) { return console.error(error); });
        }
        this.useThisCreditCard = true;
        this.newCCSelected = false;
    };
    CreditCardAddComponent.prototype.saveCreditCardToDB = function () {
        var _this = this;
        this.newCreditCard = true;
        if (this.newCCSelected) {
            if (!this.creditCardForm.valid) {
                return;
            }
            if (this.creditCard) {
                this.efilingService.rememberCreditCard(this.creditCard.id, this.creditCardForm.get('rememberCreditCard').value)
                    .subscribe(function (result) {
                    if (result.isSuccess) {
                        var creditCard = _this.creditCardForm.value;
                        creditCard.nickName = _this.creditCard.nickName;
                        creditCard.id = _this.creditCard.id;
                        _this.saveCreditCard.emit(_this.creditCard);
                        _this.newCCSelected = false;
                    }
                    else
                        console.error(result.message);
                }, function (error) { return console.error(error); });
            }
            else {
                this.efilingService.addCreditCard(this.creditCardForm.value)
                    .subscribe(function (result) {
                    if (result.isSuccess) {
                        _this.creditCard = _this.creditCardForm.value;
                        _this.creditCard.id = result.data;
                        _this.saveCreditCard.emit(_this.creditCard);
                        _this.newCCSelected = false;
                    }
                    else
                        console.error(result.message);
                }, function (error) { return console.error(error); });
            }
            this.useThisCreditCard = true;
            this.creditCardForm.value.rememberCreditCard = false;
            this.creditCard = this.creditCardForm.value;
            this.creditCard.id = this.vaultIDFromNickName;
            this.saveCreditCard.emit(this.creditCard);
        }
        else {
            this.creditCard = this.creditCardForm.value;
            this.creditCard.id = this.vaultIDFromNickName;
            this.saveCreditCard.emit(this.creditCard);
        }
    };
    CreditCardAddComponent.prototype.removeCreditCard = function () {
        this.creditCard = null;
        this.saveCreditCard.emit(null);
    };
    CreditCardAddComponent.prototype.hideMessage = function () {
        //this.message="";
    };
    CreditCardAddComponent.prototype.onCreditCardToggleChange = function (event) {
        var val = event.value;
        this.existingCard = false;
        this.creditCardForm.get('paymentType').setValue(val);
        this.creditCardPaymentTypeChange.emit(val);
        this.creditCard = this.creditCardForm.value;
        this.creditCard.paymentType = val;
        this.creditCard.paymentTypeId = val;
        this.creditCardErrorMsg.emit(this.creditCardForm.valid);
        this.saveCreditCard.emit(this.creditCard);
    };
    return CreditCardAddComponent;
}());
export { CreditCardAddComponent };
