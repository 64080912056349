import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class IdentityInterceptor implements HttpInterceptor {
	constructor(private identity: IdentityService, private authentication: AuthenticationService) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const prior = request.params;

		const user = this.authentication.currentUserSubject.value;
		let companyGuid;
		if (user) {
			companyGuid = user.organisation && user.organisation.guid;
		}

		let current = !user ? new HttpParams().set('appcode', this.identity.appCode.value)
			: !!companyGuid ? new HttpParams().set('appcode', this.identity.appCode.value)
				.set('companyGuid', user.organisation.guid)
				.set('appUserId', user.userId)
				: new HttpParams().set('appcode', this.identity.appCode.value)
					.set('appUserId', user.userId)

		request.params.keys().forEach((k) => current.set(k, prior.get(k)));

		request = request.clone({
			params: current
		});

		return next.handle(request);
	}
}
