import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NavService } from '../services/nav.service';
import { AuthenticationService } from '../services/authentication.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private router: Router, private navService: NavService, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> {
    
    const ocToLascId: string = route.queryParamMap.get('ocToLascId');
    if(ocToLascId) {
      return this.authenticationService.loginFromOc(ocToLascId).pipe(map(r => {
        if(r) {
          return r;
        }
        else {
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }));
    }

    if (this.navService.isLoggedIn) {
      return of(true);
    }

    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return of(false); 
  }
}
