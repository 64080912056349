import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../services/identity.service";
import * as i2 from "../services/authentication.service";
var IdentityInterceptor = /** @class */ (function () {
    function IdentityInterceptor(identity, authentication) {
        this.identity = identity;
        this.authentication = authentication;
    }
    IdentityInterceptor.prototype.intercept = function (request, next) {
        var prior = request.params;
        var user = this.authentication.currentUserSubject.value;
        var companyGuid;
        if (user) {
            companyGuid = user.organisation && user.organisation.guid;
        }
        var current = !user ? new HttpParams().set('appcode', this.identity.appCode.value)
            : !!companyGuid ? new HttpParams().set('appcode', this.identity.appCode.value)
                .set('companyGuid', user.organisation.guid)
                .set('appUserId', user.userId)
                : new HttpParams().set('appcode', this.identity.appCode.value)
                    .set('appUserId', user.userId);
        request.params.keys().forEach(function (k) { return current.set(k, prior.get(k)); });
        request = request.clone({
            params: current
        });
        return next.handle(request);
    };
    IdentityInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdentityInterceptor_Factory() { return new IdentityInterceptor(i0.ɵɵinject(i1.IdentityService), i0.ɵɵinject(i2.AuthenticationService)); }, token: IdentityInterceptor, providedIn: "root" });
    return IdentityInterceptor;
}());
export { IdentityInterceptor };
