<h2>Forgot Password</h2>
<div *ngIf="submitted">
  Your password has been reset. You will soon receive an email containing your new password.
</div>

<div *ngIf="!submitted">

  <div>Enter the email you previously used for your account and Submit to have a new password and you will be notified by email.</div>

  <form [formGroup]="forgotPwdForm" (ngSubmit)="sendEmail()">

    <div class="row">
      <div class="col-md-offset-4 col-md-4">
        <div class="input-group" [ngClass]="{ 'has-error': usernameInvalid }">
          <div class="input-group-addon"><i class="glyphicon glyphicon-user"></i></div>
          <input type="text" placeholder="Username (required)" formControlName="username"
                 class="form-control" />
        </div>
        <div *ngIf="usernameInvalid" class="help-block with-errors">
          Email is required
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-offset-4 col-md-4">
        <div>
          <input type="submit" value="Submit" class="btn btn-primary" [disabled]="disableSubmit">
        </div>
      </div>
    </div>

  </form>

</div>

