import { NgModule } from '@angular/core';
import { UserRoutingModule, routedUserComponents } from './user-routing.module';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [SharedModule, UserRoutingModule],
  declarations: [routedUserComponents]
})
export class UserModule { }
