import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EfilingService } from '../../../core/services/efiling.service';
import { AchAccount } from '../../../core/models';
import { State } from '@app/core/models/state-list.model';
@Component({
  selector: 'ach-account-add',
  templateUrl: './ach-account-add.component.html',
  styleUrls: ['./ach-account-add.component.css']
})
export class AchAccountAddComponent implements OnInit {

  @Output() saveAchAccount = new EventEmitter<AchAccount>();

  //achAccountId: number;
  stateList: State[];
  achAccount: AchAccount;
  achAccountAsString: string;

  achAccountForm: FormGroup;

  phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  get companyNameControl() { return this.achAccountForm.get('company'); }
  //get name(): number { return this.achAccountForm.get('name').value; }
  get paymentAccountTypeId(): number { return this.achAccountForm.get('paymentAccountTypeId').value; }
  get accountNumber(): string { return this.achAccountForm.get('accountNumber').value; }

  constructor(private efilingService: EfilingService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    
    this.efilingService.getStates()
      .subscribe(result => {
        if (result.isSuccess) {
          this.stateList = result.data.states;
        }
        else
          console.error(result.message);
      }, error => console.error(error));

    this.achAccountForm = this.fb.group({
      accountNickname: ['', Validators.required],
      paymentAccountTypeId: ['2', Validators.required],
      name: ['', Validators.required],
      company: [''],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      accountNumber: ['', Validators.required],
      routeNumber: ['', Validators.required],
      isTermsAuthFlag: [false, Validators.required]
    });
    
    this.achAccountForm.get('paymentAccountTypeId').valueChanges
      .subscribe(ct => {
        if (ct == 1) {
          this.companyNameControl.setValidators([Validators.required]);
        }
        else {
          this.companyNameControl.clearValidators();
        }
        this.companyNameControl.updateValueAndValidity();
      });

    this.efilingService.getUserAchAccount()
      .subscribe(resultGet => {
        if (resultGet.isSuccess) {
          this.achAccount = resultGet.data as AchAccount;
          //this.achAccountId = this.achAccount.id;
          this.achAccountAsString = `xxxxxx${this.achAccount.accountNumberLast4Digits}`;
          this.saveAchAccount.emit(this.achAccount);
        }
        else
          console.error(resultGet.message);
      }, error => console.error(error));
  }


  saveAchAccountToDB() {
    if (!this.achAccountForm.valid) {
      return;
    }
    this.efilingService.addAchAccount(this.achAccountForm.value)
      .subscribe(resultAdd => {
        if (resultAdd.isSuccess) {
          //this.achAccountId = resultAdd.data as number;
          this.efilingService.getUserAchAccount()
            .subscribe(resultGet => {
              if (resultGet.isSuccess) {
                this.achAccount = resultGet.data as AchAccount;
                //this.achAccountId = this.achAccount.id;
                this.achAccountAsString = `xxxxxx${this.achAccount.accountNumberLast4Digits}`;
                this.saveAchAccount.emit(this.achAccount);
              }
              else
                console.error(resultGet.message);
            }, error => console.error(error));
          //this.achAccountAsString = `XXXX-${this.accountNumber.substring(this.accountNumber.length - 4, this.accountNumber.length)}`;
        }
        else
          console.error(resultAdd.message);
      }, error => console.error(error));
  }

  removeAchAccount(): void{
    this.efilingService.deleteUserAchAccounts(this.achAccount.id)
      .subscribe(result => {
        if (result.isSuccess) {
          this.achAccount = null;
          this.saveAchAccount.emit(null);
        }
        else
          console.error(result.message);
      }, error => console.error(error));
  }

}
