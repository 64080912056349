import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Court } from '../models/court.model';
import { WebApiService } from './web-api.service';
import { GenericActionResult, NewFiling, NewFilingForSubmit, CivilCaseExt, SubsequentFiling, SubsequentFilingForSubmit, CreditCard, AchAccount } from '../models';
import { JtiDocument } from '../models/document-filing.model';
import { PartyDTO } from '../models/PartyDTO.model';
import { environment as env } from '../../../environments/environment';
import { isString } from 'util';
import { FicticiousParty } from '../models/ficticious-PartyName.model';
import { JTICourtOrderFilingDTO } from '../models/JTICourtOrderFilingDTO.model';
import { feeCreateDTO } from '../models/feeCreateDTO.model';
import { JTIFilingAttorneyOrder } from '../models/filing-attorney-order.model';
import { AdditionalDetails } from '../models/additional-details.model';
import { AdditionalAddress } from '../models/additional-address.model';
import { NumberOfParcels } from '../models/number-of-parcels.model';
import { JtiDocumentWithAdditionalDataDTO } from '../models/jti-document-with-additional-data.model';
import { uscfLoginDTO } from '../models/uscfLoginDTO.model';
import { RecentFilings } from '../models/recentfilings.model';

const BASE_URL_WEBAPI = env.webApiBaseUri;

const efilingServiceRoutes = {
  courts: '/courts',
  court: (id: string) => `/courts/${id}`,
  caseTypes: '/jti-case-types',
  caseCategories: '/jti-case-categories',
  zipCodes: '/jti-zip-codes',
  languages: '/JtiLanguage',
  getAkaTypes: '/AkaType',
  getJurisAmountlist: '/JurisAmount',
  JurisAmount: '/JurisAmount',
  scanFile: '/scan-file',

  partytype: (id: string) => `/PartyType/${id}`,
  CreateParty: '/parties',
  updateParty: '/parties',
  getPartiesByOrderNumber: (ordernumber: number) => `/parties/${ordernumber}`,
  getPartiesByOrderNumber_NewFiling: (ordernumber: number) => `/NF_parties/${ordernumber}`,
  ficticiousParty: '/jti-Ficticious-PartyName',
  fillingAttorneyByOrderNumber: '/Jti-filing-attorney-order/',
  getAttorneyListBysearchbar: '/Jti-filing-attorney-order/',
  getSavedAttorneyListByOrderNumber: (ordernumber: string) => `/Jti-filing-attorney-order/${ordernumber}`,

  newFilings: '/new-filings',
  genNewCaseTitle: '/new-filings/gen-case-title',
  subsequentFilings: '/subsequent-filings',
  createFilingFromCivilCaseExt: '/filings/civil-case-ext',
  documentTypes: '/jti-document-types/additional-information',
  updateAdditionalInfoOnDocument: (jtiOrderNumber: number, fileNumber: number) => `/new-filings/${jtiOrderNumber}/jti-documents/${fileNumber}/additional-information`,
  
  documentUploads: '/document-uploads',
  documentUploadsPDFUri: '/document-uploads-pdfuri',
  documentUploadsV2: '/document-uploads-v2',

  datetimeFiled: (orderNumber: number) => `/new-filings/${orderNumber}/datetime-filed`,
  users: '/users',
  usersOc: '/users/orange-county',
  usersall: '/users/user-all',
  usersallbyuserFirmId: '/users/user-all-firmId',
  applicationSettingList: '/application-setting/application-setting-list',
  caseQueryByCaseDocketId: (court: string, caseDocketId: string) => `/case-list-queries/${court}/case-docket-id/${caseDocketId}`,
  caseByTrackingId: (court: string, caseTrackingId: string) => `/case-list-queries/${court}/cases/${caseTrackingId}`,
  jtiOrderDetails: (orderNumber: string, transactionNumber: string) => `/new-filings/${orderNumber}/transaction-number/${transactionNumber}`,
  saveUnlawfulDetainerLog: (caseDocketId: string,HasUserSelectedYes:number) => `/unlawful-detainer-log/${HasUserSelectedYes}/case-docket-id/${caseDocketId}`,
  updateEmailAddressByOrderNumber: (ordernumber: string, emailAddress) => `/new-filings/${ordernumber}/emailAddress/${emailAddress}`,
  updateNicknameByVaultId:(id:number, nickName:string) => `/new-filings/id/${id}/nickName/${nickName}`,
  updateCourtLocation: (orderNumber: string, locationCode) => `/new-filings/${orderNumber}/locationCode/${locationCode}`,
  updateAdditionaldetailsByOrderNumber: (ordernumber: string, MessageToClerk: string, clientreferenceCode: string, matterNumber: string) => `/new-filings/${ordernumber}/messagetoClerk/${MessageToClerk}/clientCode/${clientreferenceCode}/matterNumber/${matterNumber}`,
  updateOrderdetailsByOrderNumber: (ordernumber: string, clientReferenceCode: string, matterNumber: string) => `/new-filings/${ordernumber}/clientCode/${clientReferenceCode}/matterNumber/${matterNumber}`,
  updateParcelDetailsByOrderNumber: (ordernumber: string, NumberOfParcels: number) => `/new-filings/${ordernumber}/NumberOfParcels/${NumberOfParcels}`,
  updateApplicationSettingByOrg: (name: string,value: string) => `/application-setting/name/${name}/value/${value}`,
  updateCitationDetailsByOrderNumber: (ordernumber: string, CitationDate: string, CitationIdentification: string) => `/new-filings/${ordernumber}/CitationDate/${CitationDate}/CitationIdentification/${CitationIdentification}`,
  updateAdditionalAddressByOrderNumber: '/Jti-Additional-Address',
  additionalAddress: '/Jti-Additional-Address',

  getparties: (id: string) => `/GetParty/${id}`,
  jtidocuments: '/jti-documents',
  getPartyNumber: (ordernumber: string) => `/parties/?ordernumber=${ordernumber}`,
  removeParty: (id: string) => `/parties/?id=${id}`,
  DeletePayment:(id:number)=>`/new-filings/id/${id}`,
  getFee: '/fee/generateXmlandPost',
  getFeeSusequent: '/fee',
  updatePartyDocumentid: (ordernumber: number, partyId: number, documentid: number) => `/new-filings/${ordernumber}/partyId/${partyId}/documentId/${documentid}`,
  getFilingFee: (ordernumber: string) => `/fee/${ordernumber}`,
  GetDraftsByUserId: `/courtorder/drafts`,
  // GetSubsequentPartiesByOrderNumber: (ordernumber: string) => `/subsequentparties/${ordernumber}`,

  getFeeByOrderNumber: (ordernumber: string) => `/fee/?ordernumber=${ordernumber}`,

  registerUser: '/users/register',
  forgotPassword: (email:string,token:string) => `/users/forgot-password/${email}/${token}`,
  resetPassword: '/users/reset-password',
  activateAccount: (id: string) => `/users/activate/${id}`,
  creditCards: '/credit-cards',
  creditCardsRemember: (id: number) => `/credit-cards/${id}/remember`,
  updateCreditCardFees: (orderNumber: number) => `/filings/${orderNumber}/credit-card-fees`,
  createEmailServiceFee: (orderNumber: number) => `/filings/${orderNumber}/email-service-fees`,
  achAccounts: '/ach-accounts',
  userCreditCards: '/users/credit-cards',
  userFirmCreditCards: '/users/firm-credit-cards',
  firmCreditCards: '/firm/credit-cards',
  userAchAccounts: '/users/ach-accounts',
  deleteUserAchAccounts: (achAccountId: number) => `/users/ach-accounts/${achAccountId}`,

  getRecentFilingListByUserId:  `/court-order/recent-filings`,

  getCompletedFilingListByUserId: `/completed-filings`,
  getBillingReportsListByUserId:`/billing-reports`,
  getRejectedFilingListByUserId: `/Rejected-filings`,
  getSubmittedFilingListByUserId: `/Submitted-filings`,

  getdocumentsbyOrderNumber: (jtiOrderNumber: number) => `/new-filings/${jtiOrderNumber}/jti-documents/`,

  getrevieweddocumentsbyOrderNumber: (jtiOrderNumber: number) => `/new-filings/${jtiOrderNumber}/jti-revieweddocuments/`,
  removeDrafts: (orderNumber: number) => `/courtorder/drafts/${orderNumber}`,
  getCaseCategoryByOrderNumber: '/courtorder/getCaseCategoryByOrderNumber',
};

@Injectable()
export class EfilingService {

  constructor(private webApiService: WebApiService) { }

  urlForDocumentUpload(jtiOrderNumber: number, fileNumber: number): string {

    var documentUploadUrl = efilingServiceRoutes.documentUploads;

    if(env.EnablePDFConversionOptimization === "true")
      documentUploadUrl = efilingServiceRoutes.documentUploads;

    return `${BASE_URL_WEBAPI}${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${documentUploadUrl}/${fileNumber}`;
  }

  urlForDocumentUploadByPDFUri(jtiOrderNumber: number, fileNumber: number): string {
    return `${BASE_URL_WEBAPI}${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${efilingServiceRoutes.documentUploadsPDFUri}/${fileNumber}`;
  }

  getAllCourts(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.courts);
  }


  getCourtById(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.court(id));
  }


  getAllCaseTypes(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseTypes);
  }

  GetPartyTypeById(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.partytype(id));
  }

  getCaseCategoryByCaseType(caseTypeCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseCategories + `?caseType=${caseTypeCode}`);
  }

  getZipCodesByCaseTypeAndCategory(caseTypeCode: string, caseCategoryCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.zipCodes}?caseType=${caseTypeCode}&caseCategory=${caseCategoryCode}`);
  }
  getNewFilingByOrderNumber(orderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.newFilings}/${orderNumber}`);
  }

  saveNewCaseTitle(jtiOrderNumber: number): Observable<GenericActionResult> {
    
   return this.webApiService.get(`${efilingServiceRoutes.genNewCaseTitle}/${jtiOrderNumber}`);
   
  }
  
  createNewFiling(newFiling: NewFiling): Observable<GenericActionResult> {
    const body = {
      courtLocationCode: newFiling.selectedCourt.code,
      caseCategoryCode: newFiling.selectedCaseCategory.code,
      caseTypeCode: newFiling.selectedCaseType.code,
      zipCode: newFiling.selectedZipCode.zipCode,
      locationCode: newFiling.selectedZipCode.locationCode,
      locationName: newFiling.selectedZipCode.locationName,
    };
    return this.webApiService.post(efilingServiceRoutes.newFilings, body);
  }

  createFilingFromCivilCaseExt(civilCaseExt: CivilCaseExt, complaintId: string): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.createFilingFromCivilCaseExt}/${complaintId}`, civilCaseExt);
  }
  getDocumentTypesByCaseTypeAndCategory(caseTypeCode: string, caseCategoryCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.documentTypes}?caseType=${caseTypeCode}&caseCategory=${caseCategoryCode}`);
  }
  getAllDocumentTypes(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.documentTypes);
  }
  updateAdditionalInfoOnDocument(jtiOrderNumber: number, fileNumber: number, jtiDocumentWithAdditionalDataDTO: JtiDocumentWithAdditionalDataDTO): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateAdditionalInfoOnDocument(jtiOrderNumber, fileNumber), jtiDocumentWithAdditionalDataDTO);
  }
  getPartiesByOrderNumber(ordernumber: number, filterText: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getPartiesByOrderNumber(ordernumber)}?filterText=${filterText}`);
  }
  getPartiesByOrderNumber_NewFiling(ordernumber: number, filterText: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getPartiesByOrderNumber_NewFiling(ordernumber)}?filterText=${filterText}`);
  }

  getparties(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getparties(id));

  }

  createDocument(jtiDocument: JtiDocument, isNewFiling: boolean): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.newFilings}/${jtiDocument.orderNumber}${efilingServiceRoutes.jtidocuments}?isNewFiling=${isNewFiling}`, jtiDocument);
  }

  createDocumentByPDFUri(jtiDocument: JtiDocument, isNewFiling: boolean): Observable<GenericActionResult> {

    const body = {
        WebLinkUri: "oneDriveUri",
        FileName: "fileName",
    };

    var postUrlForPDFUri = this.urlForDocumentUploadByPDFUri(jtiDocument.orderNumber, jtiDocument.fileNumber);
    return this.webApiService.post(postUrlForPDFUri, body);

    //return this.webApiService.post(`${efilingServiceRoutes.newFilings}/${jtiDocument.orderNumber}${efilingServiceRoutes.jtidocuments}?isNewFiling=${isNewFiling}`, body);
  }

  CreateParty(partyEntity: PartyDTO): Observable<GenericActionResult> {
    const body = {
      firstName: partyEntity.firstName,
      middleName: partyEntity.middleName,
      lastName: partyEntity.lastName,
      addressTypeCode: partyEntity.addressTypeCode,
      partyNumber: partyEntity.partyNumber,
      email: partyEntity.email,
      associatedWithDocuments: partyEntity.associatedWithDocuments,
      partyTypeCode: partyEntity.partyTypeCode,
      firstApperanceFeesPaid: partyEntity.firstApperanceFeesPaid,
      governmentExempt: partyEntity.governmentExempt,
      feeWaiver: partyEntity.feeWaiver,
      languageCode: partyEntity.languageCode,
      nameSuffixCode: partyEntity.nameSuffixCode,
      orderNumber: partyEntity.orderNumber,
      phone: partyEntity.phone.replace('-','').replace('-',''),
      organization: partyEntity.organization,
      requestingFilingFeesExemption: partyEntity.requestingFilingFeesExemption,
      stateCode: partyEntity.stateCode,
      street: partyEntity.street,
      city: partyEntity.city,
      suffixName: partyEntity.suffixName,
      partyDesignationTypeCode: partyEntity.partyDesignationTypeCode,
      thisIsRequired: partyEntity.thisIsRequired,
      zipCode: partyEntity.zipCode,
      addressUnknown: partyEntity.addressUnknown,
      caseTrackingId:partyEntity.caseTrackingID,
      documentId:partyEntity.documentId
    };
    return this.webApiService.post(efilingServiceRoutes.CreateParty, body);
  }
  updateParty(partyEntity: PartyDTO): Observable<GenericActionResult> {
    const body = {
      id: partyEntity.id,
      firstName: partyEntity.firstName,
      lastName: partyEntity.lastName,
      addressTypeCode: partyEntity.addressTypeCode,
      partyNumber: partyEntity.partyNumber,
      email: partyEntity.email,
      associatedWithDocuments: partyEntity.associatedWithDocuments,
      partyTypeCode: partyEntity.partyTypeCode,
      firstApperanceFeesPaid: partyEntity.firstApperanceFeesPaid,
      governmentExempt: partyEntity.governmentExempt,
      feeWaiver: partyEntity.feeWaiver,
      languageCode: partyEntity.languageCode,
      nameSuffixCode: partyEntity.nameSuffixCode,
      orderNumber: partyEntity.orderNumber,
      phone: partyEntity.phone.replace('-','').replace('-',''),
      organization: partyEntity.organization,
      requestingFilingFeesExemption: partyEntity.requestingFilingFeesExemption,
      stateCode: partyEntity.stateCode,
      street: partyEntity.street,
      city: partyEntity.city,
      suffixName: partyEntity.suffixName,
      partyDesignationTypeCode: partyEntity.partyDesignationTypeCode,
      thisIsRequired: partyEntity.thisIsRequired,
      zipCode: partyEntity.zipCode,
      addressUnknown: partyEntity.addressUnknown,
    };
    return this.webApiService.put(efilingServiceRoutes.updateParty, body);
  }
  getDocument(orderNumber: number, fileNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.newFilings}/${orderNumber}${efilingServiceRoutes.jtidocuments}/${fileNumber}`);
  }
  updateDocument(jtiDocument: JtiDocument): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.newFilings}/${jtiDocument.orderNumber}${efilingServiceRoutes.jtidocuments}/${jtiDocument.fileNumber}`, jtiDocument);
  }
  deleteDocument(jtiOrderNumber: number, fileNumber: number): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${efilingServiceRoutes.jtidocuments}/${fileNumber}`);
  }
  submitOrder(jtiOrderNumber: number, newFiling: NewFiling): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.newFilings}/${jtiOrderNumber}`, newFiling);
  }

  submitSubsequentOrder(jtiOrderNumber: number, filing: SubsequentFilingForSubmit): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.subsequentFilings}/${jtiOrderNumber}`, filing);
  }
  
  caseQueryByCaseDocketId(court: string, caseDocketId: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseQueryByCaseDocketId(court, caseDocketId));
  }
  saveUnlawfulDetainerLog(caseDocketId:string,HasUserSelectedYes:number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.saveUnlawfulDetainerLog(caseDocketId,HasUserSelectedYes));
  }
  getAllusers(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.usersall);
  }
  getAllUsersByUserFirmId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.usersallbyuserFirmId);
  }
  getApplicationSettingList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.applicationSettingList);
  }
  getCaseByTrackingId(court: string, caseTrackingId: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseByTrackingId(court, caseTrackingId));
  }
  getOrderDetails(orderNumber: string, transactionNumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.jtiOrderDetails(orderNumber, transactionNumber));
  }
  setDatetimeFiled(jtiOrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.datetimeFiled(jtiOrderNumber)}`);
  }
  login(username: string, password: string): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.users}`, { username: username, password: password });
  }
  loginOrangeCounty(): Observable<GenericActionResult> {
    return this.webApiService.postWithCookies(`${efilingServiceRoutes.usersOc}`);
  }
  getPartyNumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.Options(efilingServiceRoutes.getPartyNumber(ordernumber));
  }
  removeParty(id: string) {
    return this.webApiService.delete(efilingServiceRoutes.removeParty(id));
  }

  DeletePayment(id: number) {
    return this.webApiService.delete(efilingServiceRoutes.DeletePayment(id));
  }
 
  getLanguagesByCodeListCode(CodelistCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.languages + `?codeListCode=${CodelistCode}`);
  }
  getAkaTypeList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getAkaTypes);
  }
  CreateFicticiousParty(ficticiousEntity: FicticiousParty[]): Observable<GenericActionResult> {
    // let body = {

    // ficticiousFirstName: ficticiousEntity.ficticiousFirstName,
    // ficticiousLastName: ficticiousEntity.ficticiousLastName,
    // ficticiousMiddleName: ficticiousEntity.ficticiousMiddleName,
    // partyNumber: ficticiousEntity.partyNumber,
    // ficticiousNameTypeCode: ficticiousEntity.ficticiousNameTypeCode,
    // ficticiousNumber: ficticiousEntity.FicticiousNumber,
    // ficticiousPartyDesignationTypeCode: ficticiousEntity.ficticiousPartyDesignationTypeCode,
    // ficticiousOrganization: ficticiousEntity.ficticiousOrganization,
    // fictiousNameSuffixCode: ficticiousEntity.fictiousNameSuffixCode,
    // orderNumber: ficticiousEntity.orderNumber
    // }
    return this.webApiService.post(efilingServiceRoutes.ficticiousParty, ficticiousEntity);
  }

  updateFicticiousParty(ficticiousPartyEntity: FicticiousParty): Observable<GenericActionResult> {
    const body = {
      ficticiousPartyId: ficticiousPartyEntity.ficticiousPartyId,
      ficticiousFirstName: ficticiousPartyEntity.ficticiousFirstName,
      ficticiousLastName: ficticiousPartyEntity.ficticiousLastName,
      ficticiousMiddleName: ficticiousPartyEntity.ficticiousMiddleName,
      partyNumber: ficticiousPartyEntity.partyNumber,
      ficticiousNameTypeCode: ficticiousPartyEntity.ficticiousNameTypeCode,
      ficticiousNumber: ficticiousPartyEntity.ficticiousNumber,
      ficticiousPartyDesignationTypeCode: ficticiousPartyEntity.ficticiousPartyDesignationTypeCode,
      ficticiousOrganization: ficticiousPartyEntity.ficticiousOrganization,
      fictiousNameSuffixCode: ficticiousPartyEntity.fictiousNameSuffixCode,
      orderNumber: ficticiousPartyEntity.orderNumber
    };
    return this.webApiService.put(efilingServiceRoutes.ficticiousParty, body);
  }

  getFicticiousPartyListByOrderNumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.ficticiousParty + `?ordernumber=${ordernumber}`);
  }
  deleteficticiousParty(ficticiousPartyId: string): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.ficticiousParty}?FicticiousPartyId=${ficticiousPartyId}`);
  }
  getJtiJurisAmountByCaseTypeCode(caseTypeCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getJurisAmountlist}?caseTypeCode=${caseTypeCode}`);
  }
  getAttorneyList(searchbar: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getAttorneyListBysearchbar}?searchbar=${searchbar}`);
  }
  getSavedAttorneyListByOrdernumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getSavedAttorneyListByOrderNumber(ordernumber));
  }
  removeAttorneyById(Id: string): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.fillingAttorneyByOrderNumber}?Id=${Id}`);
  }
  postFee(jticourtorderfilingDTO: NewFilingForSubmit): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getFee, jticourtorderfilingDTO);
  }

  createFilingAttorneyByOrder(attorneyEntity: JTIFilingAttorneyOrder): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.fillingAttorneyByOrderNumber, attorneyEntity);
  }
  GetDraftsByUserId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.GetDraftsByUserId);
  }
  updateAdditionaldetails(ordernumber: string, addtinalDetails: AdditionalDetails): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateAdditionaldetailsByOrderNumber(ordernumber, addtinalDetails.messagetotheClerk, addtinalDetails.clientFilingCode, addtinalDetails.matterNumber));
  }
  updateOrderDetails(ordernumber: string, clientReferenceCode: string, matterNumber: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateOrderdetailsByOrderNumber(ordernumber,clientReferenceCode,matterNumber));
  }
  updateApplicationSetting(name: string,value: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateApplicationSettingByOrg(name,value));
  }
  updateEmailAddressByOrderNumber(ordernumber: string, emailAddress: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateEmailAddressByOrderNumber(ordernumber,emailAddress));
  }
  updatePremiseAddress(addressDetails: AdditionalAddress): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateAdditionalAddressByOrderNumber, addressDetails);
  }
  getStates(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.additionalAddress);
  }
  updateNumberofParcels(OrderNumber: string, NumberofParcels: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateParcelDetailsByOrderNumber(OrderNumber, NumberofParcels));
  }

  updateCitationInfo(OrderNumber: string, CitationDate: Date, ClientReferenceCode: string): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.newFilings}?jtiOrderNumber=${OrderNumber}&CitationDate=${CitationDate}&ClientReferenceCode=${ClientReferenceCode}`);
  }
  getFeeByOrderNumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getFeeByOrderNumber(ordernumber));
  }
  getFee_subsequent(newFiling: SubsequentFilingForSubmit): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getFeeSusequent, newFiling);
  }
  updateConditionallySealed(OrderNumber: string, ConditionallySealed: boolean): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.newFilings}?jtiOrderNumber=${OrderNumber}&ConditionallySealed=${ConditionallySealed}`);
  }
  saveCourtLocation(orderNumber: string, locationCode: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateCourtLocation(orderNumber,locationCode));
  }
  registerUser(uscfLoginDTO: uscfLoginDTO): Observable<GenericActionResult> {
    const body = {
      email: uscfLoginDTO.email,
      firmCompany: uscfLoginDTO.firmCompany,
      fName: uscfLoginDTO.fName,
      lName: uscfLoginDTO.lName,
      loginStatusID: uscfLoginDTO.loginStatusID,
      password: uscfLoginDTO.password,
      phoneNumber: uscfLoginDTO.phoneNumber,
      username: uscfLoginDTO.username,
      securityquestion: uscfLoginDTO.securityquestion,
      securityanswer: uscfLoginDTO.securityanswer,
      registrationType:uscfLoginDTO.registrationType
    };
    return this.webApiService.post(efilingServiceRoutes.registerUser, body);
  }

  forgotPassword(email:string, token:string) : Observable<GenericActionResult>{
      return this.webApiService.post(efilingServiceRoutes.forgotPassword(email,token));
  }

  resetPassword(uscfLoginDTO: uscfLoginDTO): Observable<GenericActionResult>{
    const body = {
      email: uscfLoginDTO.email,
      password: uscfLoginDTO.password,
      username: uscfLoginDTO.username,
    };
    return this.webApiService.post(efilingServiceRoutes.resetPassword, body);
  }

  activateAccount(id: string): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.activateAccount(id));
  }
  addCreditCard(creditCard: CreditCard): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.creditCards, creditCard);
  }
  saveCreditCardInVault(creditCard: CreditCard): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.creditCards, creditCard);
  }
  rememberCreditCard(id: number, rememberMe: boolean): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.creditCardsRemember(id), { rememberMe: rememberMe });
  }
  updateCreditCardFees(orderNumber: number, paymentTypeId: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateCreditCardFees(orderNumber), this.webApiService.numberToObjectBody(paymentTypeId));
  }
  createEmailServiceFee(orderNumber: number, emailcount: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.createEmailServiceFee(orderNumber), this.webApiService.numberToObjectBody(emailcount));
  }
  getUserCreditCard(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.userCreditCards);
  }
  getUserFirmCreditCard(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.userFirmCreditCards);
  }
  
  getFirmCreditCards(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.firmCreditCards);
  }
  updateNickname(id:number, nickName:string): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.updateNicknameByVaultId(id, nickName)}`);
  }

  addAchAccount(achAccount: AchAccount): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.achAccounts, achAccount);
  }
  getUserAchAccount(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.userAchAccounts);
  }
  deleteUserAchAccounts(achAccountId: number): Observable<GenericActionResult> {
    return this.webApiService.delete(efilingServiceRoutes.deleteUserAchAccounts(achAccountId));
  }

  getRecentFilingListByUserId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getRecentFilingListByUserId);
  }

  getCompletedFilingListByUserId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getCompletedFilingListByUserId);
  }
  getBillingReportListByUserId(cnfNumber:string,AlnOrderNumber:string,caseNumber:string,fromDate:string,toDate:string,userNames:string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getBillingReportsListByUserId}?cnfNumber=${cnfNumber}&alnOrderNumber=${AlnOrderNumber}&caseNumber=${caseNumber}&fromDate=${fromDate}&toDate=${toDate}&userNames=${userNames}`);
  }

  getRejectedFilingListByUserId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getRejectedFilingListByUserId);
  }

  getSubmittedFilingListByUserId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getSubmittedFilingListByUserId);
  }
  getFilingFeeByOrderNumber(OrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getFeeSusequent + `?orderNumber=${OrderNumber}`);
  }
  getdocumentsbyOrderNumber(OrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getdocumentsbyOrderNumber(OrderNumber));

  }
  getrevieweddocumentsbyOrderNumber(OrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getrevieweddocumentsbyOrderNumber(OrderNumber));

  }
  removeDrafts(orderNumber: number) {
    return this.webApiService.delete(efilingServiceRoutes.removeDrafts(orderNumber));
  }
  getCaseCategoryByOrderNumber(orderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getCaseCategoryByOrderNumber}/${orderNumber}`);
  }
  updateJurisAmount(OrderNumber: string, JurisAmountCode: string, DemandAmount: number): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.JurisAmount}?jtiOrderNumber=${OrderNumber}&JurisAmountCode=${JurisAmountCode}&DemandAmount=${DemandAmount}`);
  }
  updatePartyDocumentIdAsync(OrderNumber: number, partyId: number, documentId: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updatePartyDocumentid(OrderNumber,partyId,documentId));//}?jtiOrderNumber=${OrderNumber}&updateDocumentId=${partyId}&documentId=${documentId}`);
  }
  isValidToUpload(formFile: File): Observable<any>{
    return this.webApiService.postV2(`${efilingServiceRoutes.newFilings}${efilingServiceRoutes.scanFile}`, formFile, BASE_URL_WEBAPI, true);
  }
}

