var PaymentAccountComponent = /** @class */ (function () {
    function PaymentAccountComponent() {
        this.paymentType = 'credit';
    }
    PaymentAccountComponent.prototype.creditChecked = function () {
        this.paymentType = 'credit';
    };
    PaymentAccountComponent.prototype.achChecked = function () {
        this.paymentType = 'ach';
    };
    return PaymentAccountComponent;
}());
export { PaymentAccountComponent };
