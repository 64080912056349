var ActivateAccountComponent = /** @class */ (function () {
    function ActivateAccountComponent(_fuseConfigService, route, router, efilingService) {
        var _this = this;
        this._fuseConfigService = _fuseConfigService;
        this.route = route;
        this.router = router;
        this.efilingService = efilingService;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.id = this.route.snapshot.queryParams['id'];
        this.efilingService.activateAccount(this.id)
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.activationMessage = "Your account has been successfully activated!";
            }
            else {
                _this.activationMessage = result.message;
            }
        }, function (error) {
            _this.activationMessage = error;
            console.error(error);
        });
    }
    return ActivateAccountComponent;
}());
export { ActivateAccountComponent };
