import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { uscfLoginDTO } from '../../../core/models/uscfLoginDTO.model';
import { UserLoginStatus } from '../../../shared/enum';
var RegistrationComponent = /** @class */ (function () {
    function RegistrationComponent(fb, route, router, efilingService) {
        this.fb = fb;
        this.route = route;
        this.router = router;
        this.efilingService = efilingService;
        this.registrationSuccess = false;
        this.userEntity = new uscfLoginDTO();
    }
    RegistrationComponent.prototype.ngOnInit = function () {
        this.registrationForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            firmName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            email: ['', Validators.required],
            confirmEmail: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
            memberAgreement: ['', Validators.required],
            securityQuestion: ['', Validators.required],
            securityAnswer: ['', Validators.required],
        });
    };
    Object.defineProperty(RegistrationComponent.prototype, "disableRegisterUser", {
        get: function () {
            return !this.registrationForm.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "firstName", {
        get: function () {
            return this.registrationForm.get('firstName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "lastName", {
        get: function () {
            return this.registrationForm.get('lastName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "firmName", {
        get: function () {
            return this.registrationForm.get('firmName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "phoneNumber", {
        get: function () {
            return this.registrationForm.get('phoneNumber');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "email", {
        get: function () {
            return this.registrationForm.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "confirmEmail", {
        get: function () {
            return this.registrationForm.get('confirmEmail');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "password", {
        get: function () {
            return this.registrationForm.get('password');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "confirmPassword", {
        get: function () {
            return this.registrationForm.get('confirmPassword');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "securityQuestion", {
        get: function () {
            return this.registrationForm.get('securityQuestion');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "securityAnswer", {
        get: function () {
            return this.registrationForm.get('securityAnswer');
        },
        enumerable: true,
        configurable: true
    });
    RegistrationComponent.prototype.register = function () {
        this.errormessage = '';
        this.userEntity.fName = this.registrationForm.controls.firstName.value;
        this.userEntity.lName = this.registrationForm.controls.lastName.value;
        this.userEntity.firmCompany = this.registrationForm.controls.firmName.value;
        this.userEntity.phoneNumber = this.registrationForm.controls.phoneNumber.value;
        this.userEntity.email = this.registrationForm.controls.email.value;
        this.userEntity.password = this.registrationForm.controls.password.value;
        this.userEntity.username = this.registrationForm.controls.email.value;
        this.userEntity.loginStatusID = UserLoginStatus.active;
        this.userEntity.securityquestion = this.registrationForm.controls.securityQuestion.value;
        this.userEntity.securityanswer = this.registrationForm.controls.securityAnswer.value;
        this.callregisterUserAPI();
    };
    RegistrationComponent.prototype.callregisterUserAPI = function () {
        var _this = this;
        // todo: register user
        this.efilingService.registerUser(this.userEntity)
            .subscribe(function (result) {
            if (result.isSuccess) {
                if (result.data != null && result.data != '') {
                    _this.registrationSuccess = true;
                    _this.registrationForm.reset();
                }
                else {
                    _this.errormessage = result.message;
                }
            }
            else {
                console.error(result.message);
            }
        }, function (error) { return console.error(error); });
    };
    return RegistrationComponent;
}());
export { RegistrationComponent };
