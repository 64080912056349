import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent, TermAndConditionDialog } from './registration/register.component';
import { MailConfirmComponent } from './mail-confirm/mail-confirm.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { ResetPasswordFirmUserComponent } from './reset-password-firmuser/reset-password-firmuser.component';
export var authRoutes = [
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'registration',
                component: RegisterComponent,
            },
            {
                path: 'mail-confirm',
                component: MailConfirmComponent,
            },
            {
                path: 'activate-account',
                component: ActivateAccountComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password/:token',
                component: ResetPasswordComponent,
            },
            {
                path: 'reset-password-firmuser/:email/:token',
                component: ResetPasswordFirmUserComponent,
            },
            {
                path: 'verify-user/:email/:token/:landing',
                component: VerifyUserComponent,
            }
        ]
    },
];
var AuthRoutingModule = /** @class */ (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
export var routedAuthComponents = [LoginComponent, RegisterComponent, MailConfirmComponent, TermAndConditionDialog, ActivateAccountComponent, ForgotPasswordComponent, ResetPasswordComponent, ResetPasswordFirmUserComponent, VerifyUserComponent];
