var NavService = /** @class */ (function () {
    function NavService() {
    }
    Object.defineProperty(NavService.prototype, "isLoggedIn", {
        get: function () {
            if (localStorage.getItem('bearerToken') || localStorage.getItem('currentUser')) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    NavService.prototype.clearBearerToken = function () {
        localStorage.removeItem('bearerToken');
    };
    return NavService;
}());
export { NavService };
