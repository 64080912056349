/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snack-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./snack-bar.component";
import * as i3 from "@angular/material/snack-bar";
var styles_SnackBarComponent = [i0.styles];
var RenderType_SnackBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackBarComponent, data: {} });
export { RenderType_SnackBarComponent as RenderType_SnackBarComponent };
export function View_SnackBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_SnackBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ef-snack-bar", [], null, null, null, View_SnackBarComponent_0, RenderType_SnackBarComponent)), i1.ɵdid(1, 114688, null, 0, i2.SnackBarComponent, [i3.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnackBarComponentNgFactory = i1.ɵccf("ef-snack-bar", i2.SnackBarComponent, View_SnackBarComponent_Host_0, {}, {}, []);
export { SnackBarComponentNgFactory as SnackBarComponentNgFactory };
