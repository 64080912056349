import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Md5 } from 'ts-md5/dist/md5';
var ForgotPasswordComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    function ForgotPasswordComponent(_fuseConfigService, _formBuilder, efilingService, router, activeRoute, _snackBar) {
        this._fuseConfigService = _fuseConfigService;
        this._formBuilder = _formBuilder;
        this.efilingService = efilingService;
        this.router = router;
        this.activeRoute = activeRoute;
        this._snackBar = _snackBar;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    };
    ForgotPasswordComponent.prototype.forgotpassword = function () {
        var _this = this;
        var token = Md5.hashStr(this.forgotPasswordForm.controls.email.value).toString();
        this.efilingService.forgotPassword(this.forgotPasswordForm.controls.email.value, token)
            .subscribe(function (result) {
            if (result.isSuccess)
                _this._snackBar.open("Email has been successfully verified and sent.", null, {
                    duration: 2000,
                });
            else
                _this.errorMessage = result.message;
        }, function (error) { return console.error(error); });
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
