import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(fb, route, router, efilingService) {
        var _this = this;
        this.fb = fb;
        this.route = route;
        this.router = router;
        this.efilingService = efilingService;
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.efilingService.loginOrangeCounty()
            .subscribe(function (result) {
            if (result.isSuccess && result.data) {
                _this.setSession(result.data);
                _this.router.navigateByUrl(_this.returnUrl);
            }
            else {
                console.error(result.message);
            }
        }, function (error) { return console.error(error); });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    };
    Object.defineProperty(LoginComponent.prototype, "disableLogin", {
        get: function () {
            return !this.loginForm.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "username", {
        get: function () {
            return this.loginForm.get('username');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "password", {
        get: function () {
            return this.loginForm.get('password');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "usernameInvalid", {
        get: function () {
            return (this.username.touched || this.username.dirty) && !this.username.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "passwordInvalid", {
        get: function () {
            return (this.password.touched || this.password.dirty) && !this.password.valid;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.setSession = function (authResult) {
        // const expiresAt = moment().add(authResult.expiresIn, 'second');
        localStorage.setItem('bearerToken', authResult.bearerToken);
        // localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.efilingService.login(this.username.value, this.password.value)
            .subscribe(function (result) {
            if (result.isSuccess) {
                _this.setSession(result.data);
                _this.router.navigateByUrl(_this.returnUrl);
            }
            else {
                console.error(result.message);
            }
        }, function (error) { return console.error(error); });
    };
    return LoginComponent;
}());
export { LoginComponent };
