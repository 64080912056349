var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(authenticationService) {
        this.authenticationService = authenticationService;
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        /* const Token = localStorage.getItem("bearerToken");
        if (bearerToken) {
          request = request.clone({
            headers: request.headers.set("Authorization", `Bearer ${bearerToken}`)
          });
        }
        return next.handle(request); */
        var currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.bearerToken) {
            request = request.clone({
                headers: request.headers.set('Authorization', "Bearer " + currentUser.bearerToken)
            });
        }
        return next.handle(request);
    };
    return TokenInterceptor;
}());
export { TokenInterceptor };
