import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { uscfLoginDTO } from '@app/core/models/uscfLoginDTO.model';
import { EfilingService } from '@app/core/services/efiling.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
    selector: 'reset-password-firmuser',
    templateUrl: './reset-password-firmuser.component.html',
    styleUrls: ['./reset-password-firmuser.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordFirmUserComponent implements OnInit, OnDestroy {
    resetPasswordFirmUserForm: FormGroup;
    userEntity: uscfLoginDTO = new uscfLoginDTO();
    email: string;
    token: string;
    message: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private efilingService: EfilingService,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.token = this.route.snapshot.params['token'];
        this.email = this.route.snapshot.params['email'];

        this.resetPasswordFirmUserForm = this._formBuilder.group({
            email: [this.email],
            password: ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            barNumber: ['', [Validators.maxLength(20)]],
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordFirmUserForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordFirmUserForm.get('passwordConfirm').updateValueAndValidity();
            });

    }

    ResetPassword() {
        this.userEntity.username = this.resetPasswordFirmUserForm.controls.email.value;
        this.userEntity.email = this.resetPasswordFirmUserForm.controls.email.value;
        this.userEntity.password = this.resetPasswordFirmUserForm.controls.password.value;
        this.userEntity.barNumber = this.resetPasswordFirmUserForm.controls.barNumber.value;
        if (Md5.hashStr(this.userEntity.email) == this.token)
            this.callresetPasswordAPI();
        else
            this.message = "Please enter a valid email address";
    }

    callresetPasswordAPI() {
        this.efilingService.resetPassword(this.userEntity)
            .subscribe(result => {
                if (result.isSuccess) {
                    if (result.data != null && result.data != '')
                        this.router.navigate(['/auth/login']);
                    else
                        this.message = result.message;
                }
                else
                    console.error(result.message);
            }, error => console.error(error));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
