import { BehaviorSubject } from 'rxjs';
import { Md5 } from 'ts-md5';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./web-api.service";
import * as i2 from "./authentication.service";
import * as i3 from "./efiling.service";
import * as i4 from "@angular/material/snack-bar";
var IdentityService = /** @class */ (function () {
    function IdentityService(api, authentication, efiling, snack) {
        this.api = api;
        this.authentication = authentication;
        this.efiling = efiling;
        this.snack = snack;
        this.roles = new BehaviorSubject(null);
        this.firm = new BehaviorSubject(null);
        this.companyUsers = new BehaviorSubject(null);
        this.currentUser = new BehaviorSubject(null);
        this.allRoles = new BehaviorSubject(null);
        this.appCode = new BehaviorSubject('EFILING');
    }
    IdentityService.prototype.getUserRoles = function () {
        var _this = this;
        return this.api.post('/identity/getUserRoles', {})
            .pipe(tap(function (response) { return _this.roles.next(response.data); }));
    };
    IdentityService.prototype.getFirm = function () {
        var _this = this;
        return this.api.post('/identity/getFirm')
            .pipe(tap(function (response) { return _this.firm.next(response.data); }));
    };
    IdentityService.prototype.getAllCompanyUsers = function () {
        var _this = this;
        return this.api.get('/identity/getAllCompanyUsers')
            .pipe(tap(function (response) { return _this.companyUsers.next(response.data); }));
    };
    IdentityService.prototype.putSingleUser = function (user) {
        this.companyUsers.next(null);
        return this.api.post('/identity/editSingleUser', user);
    };
    IdentityService.prototype.mapToLogin = function (user) {
        var login = Object.assign({}, user);
        return login;
    };
    IdentityService.prototype.mapToUser = function (user) {
        var target = Object.assign({}, user);
        return target;
    };
    IdentityService.prototype.addFirmUser = function (user) {
        this.companyUsers.next(null);
        if (!user.password) {
            user.password = Math.random().toString(36).slice(-8);
        }
        return this.api.post('/identity/addFirmUser', user);
    };
    IdentityService.prototype.getApplicationRoles = function () {
        var _this = this;
        return this.api.post('/identity/ApplicationRoles')
            .pipe(tap(function (response) { return _this.allRoles.next(response.data); }));
    };
    IdentityService.prototype.startUserResetPassword = function (email, silent) {
        var _this = this;
        var token = Md5.hashStr(email).toString();
        this.efiling.forgotPassword(email, token).subscribe(function (result) {
            if (!silent) {
                var message = result.isSuccess
                    ? "Welcome instructions have been successfully sent to " + email + "."
                    : 'There was a problem resetting the password for this user.';
                _this.snack.open(message, null, { duration: 2000 });
            }
        });
    };
    IdentityService.prototype.refreshCompanyUsers = function () {
        var _this = this;
        this.getAllCompanyUsers().subscribe(function (response) { return _this.companyUsers.next(response.data); });
    };
    IdentityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdentityService_Factory() { return new IdentityService(i0.ɵɵinject(i1.WebApiService), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.EfilingService), i0.ɵɵinject(i4.MatSnackBar)); }, token: IdentityService, providedIn: "root" });
    return IdentityService;
}());
export { IdentityService };
