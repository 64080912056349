import { OnInit } from '@angular/core';
var SnackBarComponent = /** @class */ (function () {
    function SnackBarComponent(data) {
        this.data = data;
        this.messages = [''];
    }
    SnackBarComponent.prototype.ngOnInit = function () {
    };
    return SnackBarComponent;
}());
export { SnackBarComponent };
