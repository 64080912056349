<h2>Create Your Account</h2>
<!--*ngIf="!registrationSuccess"-->
<div>
  
  <div class="panel panel-info panel-top-margin">
    <div class="panel-heading">
      <h3 class="panel-title">
        Information
      </h3>
    </div>
    <div class="panel-body">
      Transactions are $9.95/per filing. Email us at <a href='mailto:aperea@alncorp.com'>aperea@alncorp.com</a> for volume discounts as well as form sets in fillable MS Word and PDF formats.
    </div>
  </div>

  <form [formGroup]="registrationForm" (ngSubmit)="register()">

    <div class="panel panel-info panel-top-margin">
      <div class="panel-heading">
        <h3 class="panel-title">
          Account information
        </h3>
        
      </div>
      <div class="panel-body">
        <div *ngIf="registrationSuccess">
          <div class="modal-dialog" style="width:1250px;">

            <div class="modal-content">

              <div class="modal-body">
                <div class="panel panel-info panel-top-margin">

                  <div class="panel-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="container shadow courtGround">
                          <div class="form-horizontal">
                            <div>
                              <label class="control-label required col-md-12">
                                Confirm your email address!
                                A confirmation email has been sent to {{email.value}}.
                                Check your inbox and click on the "Confirm my mail" link to confirm your email address.


                              </label>
                              <a [routerLink]="['/auth/login']">
                                Go back to login page
                              </a>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label style="color:red;">{{this.errormessage}}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error': (firstName.touched || firstName.dirty) && !firstName.valid }">
              <label for="firstName">First Name:</label>
              <input type="text" placeholder="First Name (required)" formControlName="firstName"
                     class="form-control" />
            </div>
            <div *ngIf="(firstName.touched || firstName.dirty) && !firstName.valid" class="help-block">
              First name is required
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error':  (lastName.touched || lastName.dirty) && !lastName.valid }">
              <label for="lastName">Last Name:</label>
              <input type="text" placeholder="Last Name (required)" formControlName="lastName"
                     class="form-control" />
            </div>
            <div *ngIf="(lastName.touched || lastName.dirty) && !lastName.valid" class="help-block">
              Last name is required
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error': (firmName.touched || firmName.dirty) && !firmName.valid }">
              <label for="firstName">Firm Name:</label>
              <input type="text" placeholder="Firm Name (required)" formControlName="firmName"
                     class="form-control" />
              <div *ngIf="(firmName.touched || firmName.dirty) && !firmName.valid" class="help-block">
                Firm name is required
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error':  (phoneNumber.touched || phoneNumber.dirty) && !phoneNumber.valid }">
              <label for="firstName">Phone Number:</label>
              <input type="number" placeholder="Phone Number (required)" formControlName="phoneNumber"
                     class="form-control" />
            </div>
            <div *ngIf="(phoneNumber.touched || phoneNumber.dirty) && !phoneNumber.valid" class="help-block">
              Phone number is required
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{'has-error': email.invalid && email.touched}">
              <label for="email">Email</label>
              <input type="text" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" placeholder="Email (required)" class="form-control" formControlName="email" />
              <span class="help-block" *ngIf="email.errors?.required && email.touched">
                Email is required
              </span>
              <span class="help-block" *ngIf="email.errors?.pattern && email.touched">
                Email is Invalid
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error': ((confirmEmail.touched || confirmEmail.dirty) && !confirmEmail.valid) || confirmEmail.value !== email.value }">
              <label for="confirmEmail">Confirm Email:</label>
              <input type="text" placeholder="Confirm Email (required)" formControlName="confirmEmail"
                     class="form-control" />
            </div>
            <div *ngIf="confirmEmail.errors?.required && confirmEmail.touched" class="help-block with-errors">
              Confirm Email is required
            </div>
            <div *ngIf="(confirmEmail.value !== email.value)" class="help-block with-errors">
              Email & Confirm Email do not match
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error': (password.touched || password.dirty) && !password.valid }">
              <label for="password">Password:</label>
              <input type="password" placeholder="Password (required)" formControlName="password"
                     class="form-control" />
            </div>
            <div *ngIf="password.errors?.required && password.touched" class="help-block with-errors">
              Password is required(Minimum length of 6 characters)
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error': ((confirmPassword.touched || confirmPassword.dirty) && !confirmPassword.valid) || confirmPassword.value !== password.value }">
              <label for="confirmPassword">Confirm Password:</label>
              <input type="password" placeholder="Confirm Password (required)" formControlName="confirmPassword"
                     class="form-control" />
            </div>
            <div *ngIf="confirmPassword.errors?.required && confirmPassword.touched" class="help-block with-errors">
              Confirm Password is required
            </div>
            <div *ngIf="(confirmPassword.value !== password.value)" class="help-block with-errors">
              Password & Confirm Password do not match
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error':  (securityQuestion.touched || securityQuestion.dirty) && !securityQuestion.valid }">
              <label for="securityQuestion">Enter Security Question:</label>
              <!--<input type="text" placeholder="Enter Security Question" formControlName="securityQuestion"
        class="form-control" />-->
              <select class="form-control" formControlName="securityQuestion">
                <option value="">--Select--</option>
                <option value="In what city or town did your mother and father meet?">
                  In what city or town did your mother and father meet?
                </option>
                <option value="What is the middle name of your oldest child?">
                  What is the middle name of your oldest child?
                </option>
                <option value="What is your favorite team?">
                  What is your favorite team?
                </option>
                <option value="What is your favorite movie?">
                  What is your favorite movie?
                </option>
                <option value="What was your favorite sport in high school?">
                  What was your favorite sport in high school?
                </option>
                <option value="What was your favorite food as a child?">
                  What was your favorite food as a child?
                </option>
                <option value="What school did you attend for sixth grade?">
                  What school did you attend for sixth grade?
                </option>
                <option value="What was the last name of your third grade teacher?">
                  What was the last name of your third grade teacher?
                </option>
                <option value="what town was your first job?">
                  what town was your first job?
                </option>
                <option value="What was the name of the company where you had your first job?">
                  What was the name of the company where you had your first job?
                </option>
              </select>
            </div>
            <div *ngIf="!securityQuestion.valid" class="help-block">
              Security question is required
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-offset-4 col-md-4">
            <div class="form-group" [ngClass]="{ 'has-error':  (securityAnswer.touched || securityAnswer.dirty) && !securityAnswer.valid }">
              <label for="securityAnswer">Security Question Answer</label>
              <input type="text" placeholder="Enter Security Answer" formControlName="securityAnswer"
                     class="form-control" />
            </div>
            <div *ngIf="(securityAnswer.touched || securityAnswer.dirty) && !securityAnswer.valid" class="help-block">
              Security answer is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-info panel-top-margin">
      <div class="panel-heading">
        <h3 class="panel-title">
          Member Agreement
        </h3>
      </div>
      <div class="panel-body">
        <label>
          <input type="checkbox" formControlName="memberAgreement" />
          I have read, fully understood and accept the Member Agreement.
        </label>

      </div>
    </div>

    <div class="row">
      <div class="col-md-offset-4 col-md-4">
        <div>
          <input type="submit" value="Sign Up" data-toggle="modal" data-target="#modal"
                 class="btn btn-primary" [disabled]="disableRegisterUser">
        </div>
      </div>
    </div>

  </form>


</div>

  <!--<div class="modal fade" id="modal" name="modal" role="dialog">
    <div class="modal-dialog" style="width:1250px;">
    
      <div class="modal-content">

        <div class="modal-body">
          <div class="panel panel-info panel-top-margin">

            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="container shadow courtGround">
                    <div class="form-horizontal">
                      <div>
                        <label class="control-label required col-md-12">
                          Confirm your email address!
                          A confirmation email has been sent to {{email.value}}.
                          Check your inbox and click on the "Confirm my mail" link to confirm your email address.


                        </label>
                        <a [routerLink]="['/auth/login']">
                          Go back to login page
                        </a>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>

    </div>
  </div>-->
  
  
  






