export class uscfLoginDTO {
  userid: string;
  email: string;
  password: string;
  username: string;
  fName: string;
  lName: string;
  firmCompany: string;
  phoneNumber: string;
  loginStatusID: number;
  securityquestion: string;
  securityanswer: string;
  registrationType:string;
  barNumber: string;
}
