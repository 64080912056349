import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
var BASE_URL_WEBAPI = env.webApiBaseUri;
var BASE_URL_CENTRAL_API = env.centralApiBaseUri;
var WebApiService = /** @class */ (function () {
    function WebApiService(httpClient) {
        this.httpClient = httpClient;
        this.options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: false };
    }
    WebApiService.prototype.get = function (path, params) {
        if (params === void 0) { params = new HttpParams(); }
        return this.httpClient.get(BASE_URL_WEBAPI + path, { params: params }).pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.put = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.httpClient
            .put(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.post = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.httpClient
            .post(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.postV3 = function (path, body) {
        if (body === void 0) { body = {}; }
        return this.httpClient
            .post(BASE_URL_CENTRAL_API + path, JSON.stringify(body), this.options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.postV2 = function (path, file, baseUrl, isFormData) {
        var options = this.options;
        if (isFormData == true) {
            options = { headers: new HttpHeaders(), withCredentials: false };
        }
        var formData = new FormData();
        formData.append('formFile', file);
        return this.httpClient
            .post(baseUrl + path, formData, options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.postWithCookies = function (path, body) {
        if (body === void 0) { body = {}; }
        this.options.withCredentials = true;
        return this.httpClient
            .post(BASE_URL_WEBAPI + path, JSON.stringify(body), this.options)
            .pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.delete = function (path) {
        return this.httpClient.delete(BASE_URL_WEBAPI + path).pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.Options = function (path) {
        return this.httpClient.options(BASE_URL_WEBAPI + path).pipe(catchError(this.formatErrors));
    };
    WebApiService.prototype.formatErrors = function (error) {
        // return throwError(error.error);
        // const errorMsg = (error.message) ? error.message :
        //  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        // todojr: log exceptions...
        return throwError(error.error);
    };
    WebApiService.prototype.numberToObjectBody = function (numberValue) {
        return { value: numberValue };
    };
    return WebApiService;
}());
export { WebApiService };
