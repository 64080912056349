var FaqFakeDb = /** @class */ (function () {
    function FaqFakeDb() {
    }
    FaqFakeDb.data = [
        {
            question: 'How do I reset my password?',
            answer: 'On the Left hand nav bar click on Administration/Profile and scroll to the password section'
        },
        {
            question: 'How do I change my email?',
            answer: 'On the Left hand nav bar click on Administration/Profile and scroll to the email section'
        },
        {
            question: 'How do I add myself to a law firm?',
            answer: 'Your firm administration will need to add your profile to the firm'
        },
        {
            question: 'Can I add another user to under my account?',
            answer: 'If you are the administrator for your firm go to the firm administration page and add the user ID to your firm.'
        },
        {
            question: ' I currently use another vendor to efile, is there a way to bring over my historical filing data?',
            answer: 'At this time there we are unable to migrate historical filing data from another vendor'
        },
        {
            question: 'How do I create an account under my law office?',
            answer: 'Your firm administration will need to add your profile to the firm.'
        },
        {
            question: 'I currently use ALN for Orange County do I need to create a username/pw to file in Los Angeles?',
            answer: 'No.  You are free to use your existing Orange County credentials and log into the Los Angeles eFiling application'
        },
        {
            question: 'When will the Orange County efiling application be integrated into the Los Angeles application suite?',
            answer: 'Orange County efiling migration is scheduled for 4th quarter 2019.  Updates are forth coming.'
        },
        {
            question: 'Do you have a “Best Practices” guide to help me avoid rejections?',
            answer: 'Yes, please follow this link [insert link]'
        },
        {
            question: 'Does ALN offer videos or webinars on eFiling using your application?',
            answer: 'Yes, please follow this link [insert link]'
        },
        {
            question: ' I currently use ALN Suite can I file directly from Forms Workflow?',
            answer: 'It is the goal to provide our clients with the ability to efile from within existing ALN applications.   Timeline to be determined'
        },
        {
            question: 'Can I request an interpreter?',
            answer: 'Yes.  For New Cases and Subsequent filings locate the interpreter drop down in the new party section'
        },
        {
            question: 'Can submit in bulk?',
            answer: 'No.  At this time the ALN application does not support bulk submissions. '
        },
        {
            question: 'I am not able to submit 2 answers/motions in one transaction.',
            answer: 'This is a technical limitation imposed on all vendors.  Please submit Motions individually as separate transactions.'
        },
        {
            question: 'Is there a judgment packet you can provide me with?',
            answer: 'Not at this time, but please visit our "Best Practices" page and scroll down to view the standard documents for submitting a judgment'
        },
        {
            question: 'What documents do I need for a name change any case category?',
            answer: 'Please visit our "Best Practices" page and scroll down to view the standard documents creating this type of new case.'
        },
        {
            question: 'Can I file into a Complex case?',
            answer: 'LASC is not accepting efiled submission for Complex Case Types.  Please check back soon for updated Complex dates'
        },
        {
            question: 'Can I file into a JCCP case?',
            answer: 'LASC is not accepting efiled submission for JCCP Case Types.  Please check back soon for updated JCCP dates'
        },
        {
            question: 'Do you provide legal counsel/advice?',
            answer: 'No, if you are need of legal assistance please contact an attorney or qualified legal adviser.'
        },
        {
            question: 'Can you help me fill out [x] document?',
            answer: 'No, if you are need of legal assistance please contact an attorney or qualified legal adviser.'
        },
        {
            question: 'Is there a place to add my own filing tracking information to my submissions?',
            answer: 'Yes, for both New Cases and Subsequent you will find Client Billing Code and Client Matter.'
        },
        {
            question: ' I forgot to add a document to my submission how do I add it (after submission)?',
            answer: 'You will have to create a new efiling transaction and include the document and submit to the court.'
        },
        {
            question: ' I have a new case with [x] number of litigants, do I have to manually enter each one?',
            answer: 'Yes the data entered into the efiling application must match the information contained on your documents.'
        },
        {
            question: 'How can I add an alias to a party?',
            answer: 'When adding a party there is a check box to add an Additional Name'
        },
        {
            question: 'Do I need an attorney to open a case/file?',
            answer: 'No.  If you are representing yourself be sure to add your address, email and phone number to your party name.'
        },
        {
            question: 'How long does it for my filings to reach the court?',
            answer: 'When you click submit your filing data is immediately sent to the court.'
        },
        {
            question: 'What document formats/types can I upload?',
            answer: 'Currently we only accept Adobe Acrobat, .pdf document format only.'
        },
        {
            question: 'What courts/states can I file into?',
            answer: 'Currently you can file in the State of California, Orange County and Los Angeles County Superior Courts'
        },
        {
            question: 'What case types do you support?',
            answer: 'Currently you can file in Orange County Civil Limited/Unlimited/Complex, Probate and Mental Health.  Los Angeles County Superior Court you can file Civil Limited and Unlimited only.'
        },
        {
            question: 'Can I pass along a note/message to the court?',
            answer: 'Yes.  When creating a submission please look for the "Message to the Clerk" text box.  You can type your message here.  Please note that message length is limited to 255 characters.'
        },
        {
            question: 'Can my legal assistant/secretary/paralegal file on my behalf?',
            answer: 'Yes.  Our application supports role based access.  There is no issue with filings being submitted on your behalf.'
        },
        {
            question: 'My complaint has [x] parties, do I need to enter all of them in your application?',
            answer: 'Yes.  The information entered into the application must match exactly to the information contained on your legal documents'
        },
        {
            question: 'How do I know what courthouse/judge/room my case has been assigned?',
            answer: 'After you submit your new case and it has been accepted by the court you will receive a notice of case assignment that will detail case assignment details.'
        },
        {
            question: 'My filing was rejected who do I contact to get more information?',
            answer: 'You may contact the court that processed and rejected the submission.'
        },
        {
            question: 'How do I cancel a submitted filing?',
            answer: 'Unfortunately, there is no mechanism in our application that will allow a transaction to be \'canceled\' after submission.  You may contact the court and request that your transaction be rejected.'
        },
        {
            question: ' I received conformed copies back without stamps, who can I contact?',
            answer: 'You may contact the court that processed submission.'
        },
        {
            question: ' How long does it take to get my document “Filed” by the court?',
            answer: 'There is not set time limit on when the court will process your transaction.'
        },
        {
            question: ' I sent in my filings and have not received any response yet?',
            answer: 'There is not set time limit on when the court will process your transaction.  '
        },
        {
            question: 'Can you provide a list of court documents?',
            answer: 'Provide link to Judicial Council Forms or Forms Workflow.'
        },
        {
            question: 'Can I file a [some document] electronically?',
            answer: 'You may check the court website for excluded documents.'
        },
        {
            question: 'How do I get my proposed order back?',
            answer: 'If the original proposed order was submitted electronically it is court procedure to send back a granted or denied order electronically.  A conformed copy of the granted or denied order will be sent to your completed filings page for review when available.'
        },
        {
            question: ' Can you tell me if my hearing was scheduled?',
            answer: 'Hearing information is found on the Notice of eFiling Confirmation you received after the court accepted your filing.'
        },
        {
            question: 'How do I reserve a motion hearing date and time?',
            answer: 'To Reserve a Motion date you are required to visit the court\'s EFM provider.  Please visit the Journal Technologies Website.'
        },
        {
            question: 'How do I get my case dismissed?',
            answer: 'Please seek qualified legal counsel on the proper procedure for case dismissal.'
        },
        {
            question: 'How can I request a hearing continuance?',
            answer: 'Please seek qualified legal counsel on the proper procedure for requesting a continuance.'
        },
        {
            question: 'I received my Summons rejected; can you tell me why? (fee waiver)',
            answer: 'It is current court procedure to reject the summons until the court grants the fee waiver.  Whether or not the fee waiver is granted a subsequent transaction is required to submit the Summons.'
        },
        {
            question: 'Can you contact the court and ask them to process my filing?',
            answer: 'Unfortunately we cannot request the court to priortize transaction processing'
        },
        {
            question: 'How do I get copies of my case documents?',
            answer: 'Conformed copies of your submitted documents can be found in the Completed Filings screen. However if you are requesting copies of the entire case inventory you will need to visit the court records department'
        },
        {
            question: ' How do I receive conformed copies of my documents?',
            answer: 'Conformed copies are returned as part of the return package after the court accepts your filings.'
        },
        {
            question: 'In what format do I get my conformed copies back? How can I view/download?',
            answer: 'Conformed copies are returned in Adobe Acrobat, .pdf, format.  Yes you can view and download.'
        },
        {
            question: 'Can you tell me where my case is assigned (courthouse, room, judge)',
            answer: 'After you submit your new case and it has been accepted by the court you will receive a notice of case assignment that will detail case assignment details.  If you did not submit the new case, but are another party on the case you may call the court - Provide court contact information.'
        },
        {
            question: 'Can you tell me my next hearing date?',
            answer: 'Unfortunately we do have access to your case data, you may need to contact the court to determine the next hearing date on your case.'
        },
        {
            question: 'How do I see a month’s/or days’ worth of billing charges?',
            answer: 'Please locate the Billing link on the left Nav bar, click and go to the your billing center. You may search for billing charges by month or day.'
        },
        {
            question: ' How do I update my payment choice?',
            answer: 'On the Left hand nav bar click on Administration/Profile and scroll to the payment section and update payment methods.'
        },
        {
            question: 'What payment methods to you accept?',
            answer: 'We accept Credit Cards; Master Card; Visa; Discover; e-Check'
        },
        {
            question: 'Outside of court fees, what other fees are there?',
            answer: 'In various courts you will have court document fees, court convenience fees and vendor transaction fees.'
        },
        {
            question: 'Do you accept filings from government entities?',
            answer: 'Yes, however we do charge a transaction fee.'
        },
        {
            question: 'Can I file with a fee waiver?',
            answer: 'Yes, however we do charge a transaction fee.'
        }
    ];
    return FaqFakeDb;
}());
export { FaqFakeDb };
