import { ElementRef } from '@angular/core';
import { version } from '../../../../../package.json';
var FooterComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FooterComponent(document, elementRef) {
        this.document = document;
        this.elementRef = elementRef;
        this.appVersion = version;
    }
    FooterComponent.prototype.ngAfterViewInit = function () {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://wchat.freshchat.com/js/widget.js"; //external script
        this.elementRef.nativeElement.appendChild(script);
        var freshChatJS = document.createElement("script");
        freshChatJS.type = "text/javascript";
        freshChatJS.src = 'assets/fresh-chat/FreshChat.js';
        this.elementRef.nativeElement.appendChild(freshChatJS);
    };
    return FooterComponent;
}());
export { FooterComponent };
