import { Injectable } from '@angular/core'
import { environment as env } from '../../../environments/environment';
import { WebApiService } from './web-api.service';
import { GenericActionResult} from '../models';
import { Observable } from 'rxjs';

const BASE_URL_CENTRAL_API = env.centralApiBaseUri;

const centralAPIServiceRoutes = {
    convertToPDFV2: '/api/Document/ConvertDocumentToPDFV2',
    oCRCompatible: '/api/Document/IsOCRCompatible?webUrl='
}

@Injectable()
export class CentralAPIService {

  constructor(private webApiService: WebApiService) { }

  urlForNonPDFUpload(): string {
    return `${BASE_URL_CENTRAL_API}${centralAPIServiceRoutes.convertToPDFV2}`;
  }

  GetOneDrivePDFUri(formFile: File): Observable<any> {
    return this.webApiService.postV2(centralAPIServiceRoutes.convertToPDFV2, formFile, BASE_URL_CENTRAL_API, true);
  }
  checkOCRCompatible(awsURL: string): Observable<any> {
    return this.webApiService.postV3(`${centralAPIServiceRoutes.oCRCompatible}${awsURL}`);
  }
}