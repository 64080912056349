var ɵ0 = {
    NAV: {
        APPLICATIONS: 'Applications',
        SAMPLE: {
            TITLE: 'Sample',
            BADGE: '25'
        },
        DASHBOARD: { TITLE: 'DashBoard' },
        EFILE: { TITLE: 'eFiling' },
        SEARCH: { TITLE: 'Search Transaction' },
        SERVICES: { TITLE: 'Services' },
        DRAFTS: { TITLE: 'Drafts' },
        CONCIERGE: { TITLE: 'Concierge' },
        COPY: { TITLE: 'Courtesy Copy' },
        PROCESS: { TITLE: 'Process Serve' },
        PRODUCTS: { TITLE: 'ALN Products' },
        FORMSWF: { TITLE: 'Forms Workflow' },
        SMARTDOCK: { TITLE: 'Smart Dockets' },
        COURTRULES: { TITLE: 'Court Rules' },
        DOCKETALERTS: { TITLE: 'Docket Alerts' },
        BILLING: { TITLE: 'Billing & Reports' },
        FAQ: { TITLE: 'FAQ' },
        KB: { TITLE: 'Knowledge Base' },
        ADMIN: { TITLE: 'Administration' },
        PROFILE: { TITLE: 'Profile' },
        ENTERPRISE: { TITLE: 'Enterprise Suite' },
        CALENDAR: { TITLE: 'Calendar' },
        MAIL: { TITLE: 'Mail' },
        PRODUCTMANAGEMENT: { TITLE: 'Product Management' },
        SETTINGS: { TITLE: 'Settings' },
        ORDER: { TITLE: 'Order' },
        MANAGEFIRMPAYMENT: { TITLE: 'Manage Firm Payment' },
    }
};
export var locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
