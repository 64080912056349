import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { uscfLoginDTO } from '@app/core/models/uscfLoginDTO.model';
import { UserLoginStatus } from '@app/shared/enum';
import { EfilingService } from '@app/core/services/efiling.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, NavigationExtras } from '@angular/router';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;
    userEntity: uscfLoginDTO = new uscfLoginDTO();
    errormessage: string;
    isFirmVisible=false;
    phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    isRegisterButtonClicked = false;
    registrationTypeList: any[] = [
        { id: 0, value: 'I am registering as Self-Represented or Sole Practitioner'},
        { id: 1, value: 'I am registering as a Firm or Organization'}
      ]
    private _unsubscribeAll: Subject<any>;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private efilingService: EfilingService,
        public dialog: MatDialog,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
  
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerForm = this._formBuilder.group({
            registrationType: ['', Validators.required],
            fname: ['', Validators.required],
            lname: ['', Validators.required],
            firmName: [''],
            email: ['', [Validators.required, Validators.email]],
            /*    emailConfirm   : ['', [Validators.required, Validators.email,confirmEmailValidator]], */
            password: ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            terms: ['', Validators.required]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    get disableRegisterButton() {
        return this.registerForm.invalid || this.isRegisterButtonClicked;
      }

    registrationChange(id:number){
        const firmName = this.registerForm.get('firmName');
          if(id==1){
            this.isFirmVisible=true;
            firmName.setValidators([Validators.required]);
          }
          else{
            this.isFirmVisible=false;
            firmName.clearValidators();
          }
          firmName.updateValueAndValidity();
    }

    register() {
        this.isRegisterButtonClicked = true;
        this.errormessage = '';
        this.userEntity.fName = this.registerForm.controls.fname.value;
        this.userEntity.lName = this.registerForm.controls.lname.value;
        this.userEntity.firmCompany = this.registerForm.controls.firmName.value;
        this.userEntity.email = this.registerForm.controls.email.value;
        this.userEntity.password = this.registerForm.controls.password.value;
        this.userEntity.username = this.registerForm.controls.email.value;
        this.userEntity.loginStatusID = UserLoginStatus.inactive;
        this.userEntity.registrationType = this.registerForm.controls.registrationType.value;
        this.callregisterUserAPI();
        // this.openDialog();
        // this.goToConfirmPage();
    }

    callregisterUserAPI() {
        this.efilingService.registerUser(this.userEntity)
            .subscribe(result => {
                if (result.isSuccess) {
                    if (result.data != null && result.data != '') {
                        this.registerForm.reset();
                        this.goToConfirmPage();
                    }
                    else {
                        this.errormessage = result.message;
                    }
                }
                else
                {
                    console.error(result.message);
                }
                this.isRegisterButtonClicked = false;
            }, 
            error => {
                console.error(error);
                this.isRegisterButtonClicked = false;
            });
    }

    goToConfirmPage() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                email: this.userEntity.email
            }
        };
        this.router.navigate(['/auth/mail-confirm'], navigationExtras);
    }

    openTermsAndConditonDialog(): void {
        const dialogRef = this.dialog.open(TermAndConditionDialog, {
            width: '650px',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

@Component({
    selector: 'terms-and-condition',
    templateUrl: 'terms-and-condition.html',
})
export class TermAndConditionDialog {}

/**
 * Confirm email validator
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */

export const confirmEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const email = control.parent.get('email');
    const emailConfirm = control.parent.get('emailConfirm');

    if (!email || !emailConfirm) {
        return null;
    }

    if (emailConfirm.value === '') {
        return null;
    }

    if (email.value === emailConfirm.value) {
        return null;
    }

    return { emailNotMatching: true };
};


/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
