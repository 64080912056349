import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
var EFilingProgressComponent = /** @class */ (function () {
    function EFilingProgressComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.uploadProgress = -1;
        this.mode = 'determinate';
    }
    EFilingProgressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mode = this.data.mode;
        if (this.data.progress) {
            this.data.progress.subscribe(function (p) {
                _this.uploadProgress = p;
                if (_this.uploadProgress === 100) {
                    _this.dialogRef.close(_this.uploadProgress);
                }
            });
        }
    };
    return EFilingProgressComponent;
}());
export { EFilingProgressComponent };
