import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user';

@Component({
  selector: 'redirect',
  template: 'redirecting...'
})
export class AppGatewayComponent implements OnInit {
  
  currentUser: User;
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    var disableGuardForCentralAppsLink = 'disableGuard=true';

    if (this.route.snapshot.params['landingpage'] === 'mail') {
      window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/mail?${disableGuardForCentralAppsLink}`;
    }
    else if (this.route.snapshot.params['landingpage'] === 'calendar') {
      window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/calendar?${disableGuardForCentralAppsLink}`;
    }
  }
}
