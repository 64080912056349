import { OnInit } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
var VerifyUserComponent = /** @class */ (function () {
    function VerifyUserComponent(router, route) {
        this.router = router;
        this.route = route;
    }
    VerifyUserComponent.prototype.ngOnInit = function () {
        this.encodeEmail = Md5.hashStr(this.route.snapshot.params['email']).toString();
        this.token = this.route.snapshot.params['token'];
        this.landing = this.route.snapshot.params['landing'];
        if (this.encodeEmail == this.token && this.landing == "reset-password")
            this.router.navigate(["/auth/reset-password/" + this.token]);
        else if (this.encodeEmail == this.token && this.landing == "NewFirmUser")
            this.router.navigate(["/auth/reset-password-firmuser/" + this.route.snapshot.params['email'] + "/" + this.token]);
        else
            this.router.navigate(['/auth/login']);
    };
    return VerifyUserComponent;
}());
export { VerifyUserComponent };
