import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
var SampleComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    function SampleComponent(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }
    return SampleComponent;
}());
export { SampleComponent };
