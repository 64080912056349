import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaymentAccountComponent } from './payment-account/payment-account.component';

export const userRoutes: Routes = [
  {
    path: 'user',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'registration',
        component: RegistrationComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'payment-account',
        component: PaymentAccountComponent
      }
    ]
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(userRoutes)],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule]
})
export class UserRoutingModule { }

export const routedUserComponents = [LoginComponent, RegistrationComponent, ForgotPasswordComponent, PaymentAccountComponent];
