<div *ngIf="!achAccount" fxLayout="column" fxLayoutAlign="center center">
  <form [formGroup]="achAccountForm" (ngSubmit)="saveAchAccountToDB()">
    
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Account Nickname *</mat-label>
          <input matInput formControlName="accountNickname" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('accountNickname').hasError('required')">
              Account Nickname is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Checking Type *</mat-label>
          <mat-select formControlName="paymentAccountTypeId">
            <mat-option value="2">Personal</mat-option>
            <mat-option value="1">Business</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Account Name *</mat-label>
          <input matInput formControlName="name" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('name').hasError('required')">
              Account Name is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Company Name</mat-label>
          <input matInput formControlName="company" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('company').hasError('required')">
              Company Name is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Street *</mat-label>
          <input matInput formControlName="address" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('address').hasError('required')">
              Street Address is required
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>City *</mat-label>
          <input matInput formControlName="city" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('city').hasError('required')">
              City is required
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>State *</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let list of stateList" value={{list.code}}>
            {{list.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="achAccountForm.get('state').hasError('required')">
              State is required
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30" appearance="outline">
          <mat-label>Zip code *</mat-label>
          <input matInput formControlName="zipCode" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('zipCode').hasError('required')">
              Zip Code is required
          </mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <mat-form-field fxFlex="48" appearance="outline">
          <mat-label>Phone Number *</mat-label>
          <input matInput formControlName="phone" [textMask]="{mask: phonemask}" placeholder="(xxx)-xxx-xxxx" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('phone').hasError('required')">
              Phone number is required
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="48" appearance="outline">
          <mat-label>Email *</mat-label>
          <input matInput formControlName="email" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('email').hasError('required')">
              Email is required
          </mat-error>
          <mat-error *ngIf="achAccountForm.get('email').hasError('email')">
              Email format is not correct
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Account Number *</mat-label>
          <input matInput formControlName="accountNumber" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('accountNumber').hasError('required')">
              Account Number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>Route Number *</mat-label>
          <input matInput formControlName="routeNumber" (keypress)="hideMessage()">
          <mat-error *ngIf="achAccountForm.get('routeNumber').hasError('required')">
              Routing Number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="paymentAccountTypeId == 2">
        <div>Please allow for an account validation period of  2 – 4 days.</div>
        <div>
            <mat-checkbox formControlName="isTermsAuthFlag">
              I Agree *
            </mat-checkbox>
            <mat-error *ngIf="achAccountForm.get('isTermsAuthFlag').hasError('required')">
                You must accept the terms to continue.
            </mat-error>
        </div>
        <div>
          <div id="divALN" style="border: solid 1px #c2c2c2; height:120px;overflow:auto;">
            By checking the appropriate box and using this ACH payment system, you the customer (hereinafter “Customer”) agree to be bound by these
            terms. Customer authorizes American LegalNet, Inc. or its designee successor or assign (hereinafter “Vendor”) to withdraw any amounts,
            including any and all filing fees, transaction fees and handling fees now due or hereinafter imposed, owed by Customer under this
            Pre-Authorized Bank Account Withdrawal Agreement (“Agreement”) by initiating debit entries to Customer’s account at the financial
            institution (hereinafter “Bank”) indicated in this agreement or at any such other Bank as Customer may from time to time use.
            Because the full amount of any and all filing fees, transaction fees and handling fees may not be known until after a filing is accepted,
            partially accepted, or rejected by the Superior Court of California – County of Orange (hereinafter the “Court”), Customer further
            authorizes Vendor to withdraw any additional amounts due after a filing has been accepted, partially accepted, or rejected by the Court.
            Further, Customer authorizes Bank to accept and to charge any debit entries initiated by Vendor to Customer’s account.
            In the event that Vendor withdraws erroneously from Customer’s account, Customer authorizes Vendor to credit Customer’s account for the
            amount erroneously withdrawn.  Customer understands that the foregoing ACH authorization is a fundamental condition to induce Vendor to
            accept this Agreement.  Consequently, such authorization is intended to be irrevocable and if cancelled, Customer authorizes Bank to pay
            a single and final ACH debit to Vendor equal to any balance due on Agreement. In the event that Vendor is unable to collect any ACH debit
            to Customer, in its sole discretion, may either deem such an event as default in accordance with the Agreement and may invoice Customer for
            a $30.00 processing fee in such invoices.
          </div>
        </div>
      </div>

      <div *ngIf="paymentAccountTypeId == 1">
        <div>Please <strong>FAX</strong> the completed form to (866)299-6053  Attention: ACH authorization</div>
        <div>Please allow 2 – 4 business days for account validation.</div>
        <div>
          <a target="_blank" href="assets/pdfs/PreAuthorizedBankAccount.pdf">Download Payment Authorization Form</a>
        </div>
      </div>

      <div>
        <mat-error *ngIf="(this.achAccountForm.touched || this.achAccountForm.dirty) && !this.achAccountForm.valid">
            Please fill in all the required fields to continue
        </mat-error>
      </div>

      <div>
        <button class="mat-raised-button mat-accent" type="submit">Save ACH Account to profile</button>
      </div>

  </form>
</div>

<div *ngIf="achAccount">
  <div fxLayout="column" fxLayoutAlign="space-around start">
    <div>
      <h3 *ngIf="this.achAccount.accountVerifyStatusTypeId === 1"><strong>ACH Account - Pending Verification</strong></h3>
      <h3 *ngIf="this.achAccount.accountVerifyStatusTypeId === 2"><strong>Use saved ACH account</strong></h3>
      <h3 *ngIf="this.achAccount.accountVerifyStatusTypeId === 3"><strong>ACH Account - On Hold</strong></h3>
    </div>
    <div>
      <div *ngIf="this.achAccount.accountVerifyStatusTypeId === 1"><h3>This account is pending verification and cannot be used. <br/>Please use the credit card option</h3></div>
      <div *ngIf="this.achAccount.accountVerifyStatusTypeId === 3"><h3>This account is on hold and cannot be used. <br />Please use the credit card option</h3></div>
      <div *ngIf="this.achAccount.accountVerifyStatusTypeId === 2">
        <h3>Name: {{this.achAccount.name}}</h3>
      </div>
      <div>
        <h3>Account Number: {{achAccountAsString}}</h3>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-around end">
    <div>
      <button class="mat-raised-button mat-warn" (click)="removeAchAccount()">Remove this ACH account</button>
    </div>
  </div>
  
</div>
