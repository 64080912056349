import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegisterComponent, TermAndConditionDialog } from './registration/register.component';
import { MailConfirmComponent } from './mail-confirm/mail-confirm.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { ResetPasswordFirmUserComponent } from './reset-password-firmuser/reset-password-firmuser.component';

export const authRoutes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'registration',
        component: RegisterComponent,
      },
      {
        path: 'mail-confirm',
        component: MailConfirmComponent,
      },
      {
        path: 'activate-account',
        component: ActivateAccountComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
      },
      {
        path: 'reset-password-firmuser/:email/:token',
        component: ResetPasswordFirmUserComponent,
      },
      {
        path: 'verify-user/:email/:token/:landing',
        component: VerifyUserComponent,
      }
    ]
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(authRoutes)],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
})
export class AuthRoutingModule { }

export const routedAuthComponents = [LoginComponent, RegisterComponent, MailConfirmComponent, TermAndConditionDialog, ActivateAccountComponent, ForgotPasswordComponent, ResetPasswordComponent, ResetPasswordFirmUserComponent, VerifyUserComponent];
