import { FaqFakeDb } from 'app/fake-db/faq';
var FakeDbService = /** @class */ (function () {
    function FakeDbService() {
    }
    FakeDbService.prototype.createDb = function () {
        return {
            // FAQ
            faq: FaqFakeDb.data,
        };
    };
    return FakeDbService;
}());
export { FakeDbService };
