<div id="reset-password-firmuser" fxLayout="column">

    <div id="reset-password-firmuser-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-firmuser-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/alnlogo.png">
            </div>
            <div class="content">
                <h2>Welcome to American LegalNet.</h2>

                <p>You are one step away from electronically filing in the industry's premiere portal.</p>
            </div>

            <form name="resetPasswordFirmUserForm" [formGroup]="resetPasswordFirmUserForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" readonly>
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordFirmUserForm.get('password').hasError('required')">
                        Password is required
                    </mat-error>
                    <mat-error *ngIf="resetPasswordFirmUserForm.get('password').hasError('minlength') && !resetPasswordFirmUserForm.get('password').hasError('required')">
                        Password should contain 6 characters        
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordFirmUserForm.get('passwordConfirm').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordFirmUserForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordFirmUserForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Passwords must match
                    </mat-error>
                </mat-form-field>
                <mat-error>{{message}}</mat-error>

                <mat-form-field appearance="outline">
                    <mat-label>BAR Number</mat-label>
                    <input matInput type="text" formControlName="barNumber">
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent" (click)="ResetPassword()"
                        aria-label="Save My Password" [disabled]="resetPasswordFirmUserForm.invalid">
                    Save My Password
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Go back to login</a>
            </div>
            
        </div>

    </div>

</div>
