import { Component } from '@angular/core';

@Component({
  selector: 'payment-account',
  templateUrl: './payment-account.component.html'
})
export class PaymentAccountComponent {

  paymentType = 'credit';

  constructor() {
  }

  creditChecked() {
    this.paymentType = 'credit';
  }
  achChecked() {
    this.paymentType = 'ach';
  }
  
}

