import { Injectable } from '@angular/core';

@Injectable()
export class NavService {
  
  get isLoggedIn(): boolean {
    if (localStorage.getItem('bearerToken') || localStorage.getItem('currentUser')) {
      return true;
    }
    return false;
  }

  public clearBearerToken(): void {
    localStorage.removeItem('bearerToken');
  }
  
}

