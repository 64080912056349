import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'redirect',
  template: 'verifying...'
})
export class VerifyUserComponent implements OnInit {
  
  encodeEmail: string;
  token: string;
  landing: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.encodeEmail = Md5.hashStr(this.route.snapshot.params['email']).toString();
    this.token =this.route.snapshot.params['token'];
    this.landing=this.route.snapshot.params['landing'];
    if(this.encodeEmail == this.token && this.landing=="reset-password")
        this.router.navigate([`/auth/reset-password/${this.token}`]);
    else if(this.encodeEmail == this.token && this.landing=="NewFirmUser")
        this.router.navigate([`/auth/reset-password-firmuser/${this.route.snapshot.params['email']}/${this.token}`]);
    else
        this.router.navigate(['/auth/login']);
  }
}
