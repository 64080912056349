import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards';
import { AppGatewayComponent } from './core/appgateway.component';
var routes = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard', canActivate: [AuthGuard] },
    { path: 'dashboard', loadChildren: './modules/dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
    { path: 'auth', loadChildren: './modules/auth/auth.module#AuthModule', canActivate: [AuthGuard] },
    { path: 'new-filing', loadChildren: 'app/modules/new-filing/new-filing.module#NewFilingModule', canActivate: [AuthGuard] },
    { path: 'products', loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [AuthGuard] },
    { path: 'product-efiling', loadChildren: 'app/modules/product-efiling/product-efiling.module#ProductEfilingModule', canActivate: [AuthGuard] },
    { path: 'user', loadChildren: 'app/modules/user/user.module#UserModule', canActivate: [AuthGuard] },
    { path: 'subsequent', loadChildren: 'app/modules/subsequent-filing/subsequent-filing.module#SubsequentfilingModule', canActivate: [AuthGuard] },
    { path: 'drafts', loadChildren: 'app/modules/drafts/drafts.module#DraftsModule', canActivate: [AuthGuard] },
    { path: 'recent-filings', loadChildren: 'app/modules/recent-filings/recent-filings.module#RecentFilingsModule', canActivate: [AuthGuard] },
    { path: 'completed-filings', loadChildren: 'app/modules/completed-filings/completed-filings.module#CompletedFilingsModule', canActivate: [AuthGuard] },
    { path: 'billing-reports', loadChildren: 'app/modules/billing-reports/billing-reports.module#BillingReportsModule', canActivate: [AuthGuard] },
    { path: 'displayorder', loadChildren: 'app/modules/displayorder/displayorder.module#DisplayOrderModule', canActivate: [AuthGuard] },
    { path: 'services', loadChildren: 'app/modules/services/services.module#ServicesModule', canActivate: [AuthGuard] },
    { path: 'profile', loadChildren: 'app/modules/profile/profile.module#ProfileModule', canActivate: [AuthGuard] },
    { path: 'order-update', loadChildren: 'app/modules/order-update/order-update.module#OrderUpdateModule', canActivate: [AuthGuard] },
    { path: 'manage-firm-payment', loadChildren: 'app/modules/manage-firm-payment/manage-firm-payment.module#ManageFirmPaymentModule', canActivate: [AuthGuard] },
    { path: 'settings', loadChildren: 'app/modules/setting/setting.module#SettingModule', canActivate: [AuthGuard] },
    { path: 'appgateway/:landingpage', component: AppGatewayComponent, pathMatch: 'full' },
    { path: 'pages', loadChildren: './main/pages/pages.module#PagesModule' },
    { path: 'user-management', loadChildren: './modules/user-management/user-management.module#UserManagementModule', canActivate: [AuthGuard] },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
