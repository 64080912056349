import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
var LoginComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    function LoginComponent(_fuseConfigService, _formBuilder, route, router, authenticationService, efilingService) {
        this._fuseConfigService = _fuseConfigService;
        this._formBuilder = _formBuilder;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.efilingService = efilingService;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        localStorage.removeItem('currentUser');
        this.authenticationService.currentUserSubject.next(null);
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.authenticationService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value)
            .pipe(first())
            .subscribe(function (data) {
            _this.router.navigate([_this.returnUrl]);
        }, function (error) { return _this.message = 'Username or password is incorrect'; });
    };
    LoginComponent.prototype.hideMessage = function () {
        this.message = '';
    };
    return LoginComponent;
}());
export { LoginComponent };
