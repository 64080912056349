import { environment as env } from '../../../environments/environment';
var BASE_URL_CENTRAL_API = env.centralApiBaseUri;
var centralAPIServiceRoutes = {
    convertToPDFV2: '/api/Document/ConvertDocumentToPDFV2',
    oCRCompatible: '/api/Document/IsOCRCompatible?webUrl='
};
var CentralAPIService = /** @class */ (function () {
    function CentralAPIService(webApiService) {
        this.webApiService = webApiService;
    }
    CentralAPIService.prototype.urlForNonPDFUpload = function () {
        return "" + BASE_URL_CENTRAL_API + centralAPIServiceRoutes.convertToPDFV2;
    };
    CentralAPIService.prototype.GetOneDrivePDFUri = function (formFile) {
        return this.webApiService.postV2(centralAPIServiceRoutes.convertToPDFV2, formFile, BASE_URL_CENTRAL_API, true);
    };
    CentralAPIService.prototype.checkOCRCompatible = function (awsURL) {
        return this.webApiService.postV3("" + centralAPIServiceRoutes.oCRCompatible + awsURL);
    };
    return CentralAPIService;
}());
export { CentralAPIService };
