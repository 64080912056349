/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./appgateway.component";
import * as i2 from "@angular/router";
import * as i3 from "./services/authentication.service";
var styles_AppGatewayComponent = [];
var RenderType_AppGatewayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppGatewayComponent, data: {} });
export { RenderType_AppGatewayComponent as RenderType_AppGatewayComponent };
export function View_AppGatewayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["redirecting..."]))], null, null); }
export function View_AppGatewayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "redirect", [], null, null, null, View_AppGatewayComponent_0, RenderType_AppGatewayComponent)), i0.ɵdid(1, 114688, null, 0, i1.AppGatewayComponent, [i2.ActivatedRoute, i3.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppGatewayComponentNgFactory = i0.ɵccf("redirect", i1.AppGatewayComponent, View_AppGatewayComponent_Host_0, {}, {}, []);
export { AppGatewayComponentNgFactory as AppGatewayComponentNgFactory };
