import { of } from 'rxjs';
import { map } from 'rxjs/operators';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, navService, authenticationService) {
        this.router = router;
        this.navService = navService;
        this.authenticationService = authenticationService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var ocToLascId = route.queryParamMap.get('ocToLascId');
        if (ocToLascId) {
            return this.authenticationService.loginFromOc(ocToLascId).pipe(map(function (r) {
                if (r) {
                    return r;
                }
                else {
                    _this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                }
            }));
        }
        if (this.navService.isLoggedIn) {
            return of(true);
        }
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return of(false);
    };
    return AuthGuard;
}());
export { AuthGuard };
