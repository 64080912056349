import { OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { uscfLoginDTO } from '@app/core/models/uscfLoginDTO.model';
import { Md5 } from 'ts-md5/dist/md5';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(_fuseConfigService, _formBuilder, router, route, efilingService) {
        this._fuseConfigService = _fuseConfigService;
        this._formBuilder = _formBuilder;
        this.router = router;
        this.route = route;
        this.efilingService = efilingService;
        this.userEntity = new uscfLoginDTO();
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.route.snapshot.params['token'];
        this.resetPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
        });
    };
    ResetPasswordComponent.prototype.ResetPassword = function () {
        this.userEntity.username = this.resetPasswordForm.controls.email.value;
        this.userEntity.email = this.resetPasswordForm.controls.email.value;
        this.userEntity.password = this.resetPasswordForm.controls.password.value;
        if (Md5.hashStr(this.userEntity.email) == this.token)
            this.callresetPasswordAPI();
        else
            this.message = "Please enter a valid email address";
    };
    ResetPasswordComponent.prototype.callresetPasswordAPI = function () {
        var _this = this;
        this.efilingService.resetPassword(this.userEntity)
            .subscribe(function (result) {
            if (result.isSuccess) {
                if (result.data != null && result.data != '')
                    _this.router.navigate(['/auth/login']);
                else
                    _this.message = result.message;
            }
            else
                console.error(result.message);
        }, function (error) { return console.error(error); });
    };
    /**
     * On destroy
     */
    ResetPasswordComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export var confirmPasswordValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var password = control.parent.get('password');
    var passwordConfirm = control.parent.get('passwordConfirm');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (passwordConfirm.value === '') {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { passwordsNotMatching: true };
};
