import { NgModule } from '@angular/core';
import { AuthRoutingModule, routedAuthComponents } from './auth-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/shared/material/material.module';
import { TermAndConditionDialog } from './registration/register.component';
import { TextMaskModule } from 'angular2-text-mask';
@NgModule({
  imports: [
    SharedModule, 
    AuthRoutingModule,
    MaterialModule,
    FuseSharedModule,
    TextMaskModule
  ],
  declarations: [routedAuthComponents],
  entryComponents: [TermAndConditionDialog]
})
export class AuthModule { }
