export enum DBOperation {
  create = 1,
  update = 2,
  delete = 3
}
export enum JurisAmountRange {
O10K = 1,
O25K = 2,
U10K = 3
}
export enum UserLoginStatus {
  inactive = 0,
  active = 1,
  manualDisable = 2
}
